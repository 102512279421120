import React, { useState, useEffect } from 'react';
import './transaction.css';
import Header from '../transactionHistory/components/Header';
import TransactionDataTable from "../transactionHistory/components/TransactionDataTable";
import HistoryDataTable from "./components/HistoryDataTable";
import { getRequest } from "../../../Resquest";
import Modal from "./components/Modal";
import { AnimatePresence } from "framer-motion";
import VerifyOTP from "./components/VerifyOTP";
import { useTranslation } from "react-i18next";
import CreatePaymentDetailModal from "./components/CreatePaymentDetailModal";
import BalanceInsufficientModal from "./components/BalanceInsufficientModal";

const TransactionHistory = ({ user }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [activeTable, setActiveTable] = useState(true);
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [insufficientBalance, setInsufficientBalance] = useState(false);
    const [initialData, setInitialData] = useState({
        available_to_withdraw: "0.00",
        upcoming: "0.00",
        total_withdrawn: "0.00"
    });
    const [transactionData, setTransactionData] = useState([]);
    const [isOpen, setIsOpen] = useState(false); // For main modal
    const [isCreatePaymentModalOpen, setIsCreatePaymentModalOpen] = useState(false); // For "create payment detail" modal
    const [isOtpVisible, setIsOtpVisible] = useState(false);
    const [id, setId] = useState(false);

    const modalVisible = () => {
        if (initialData.available_to_withdraw < 500) {
            setInsufficientBalance(true);
        } else if (paymentDetails.length >= 1) {
            setIsOpen(true);
        } else {
            setIsCreatePaymentModalOpen(true);
        }
    };


    const getTransactions = async () => {
        setIsLoading(true);
        try {
            const response = await getRequest("/seller/balances/");
            if (response.status === 200) {
                setTransactions(response.data);
                setInitialData({
                    available_to_withdraw: response.data.available_to_withdraw || "0.00",
                    upcoming: response.data.upcoming || "0.00",
                    total_withdrawn: response.data.total_withdrawn || "0.00"
                });
                setTransactionData(response.data.sale_statuses);
            }
        } catch (e) {
            console.error("Error while getting balance", e);
        } finally {
            setIsLoading(false);
        }
    };

    const getPaymentDetails = async () => {
        try {
            const response = await getRequest("/bank-settlements/");
            if (response.status === 200) {
                setPaymentDetails(response.data.results);
            } else {
                console.error("Error while getting payment details", response);
            }
        } catch (e) {
            console.error("Error while fetching payment details", e);
        }
    };

    useEffect(() => {
        getTransactions();
        getPaymentDetails();
    }, []);

    return (
        <div className="main_transaction">
            <div className="transaction_container">
                <h1 className="transaction_title">
                    {t('transactions.title')}
                    <button onClick={modalVisible}>{t('transactions.btn')}</button>
                </h1>
                <Header
                    setActiveTable={setActiveTable}
                    activeTable={activeTable}
                    initialData={initialData}
                />
                {activeTable ? (
                    <TransactionDataTable transactionData={transactionData} />
                ) : (
                    <HistoryDataTable />
                )}
                <AnimatePresence
                    initial={false}
                    mode={'wait'}
                    onExitComplete={() => null}
                >
                    {isOpen && (
                        <Modal
                            setVisible={setIsOpen}
                            availableToWithdraw={initialData.available_to_withdraw}
                            setOtpVisible={setIsOtpVisible}
                            setId={setId}
                        />
                    )}
                </AnimatePresence>
                <AnimatePresence
                    initial={false}
                    mode={'wait'}
                    onExitComplete={() => null}
                >
                    {isOtpVisible && (
                        <VerifyOTP Id={id} setOtpVisible={setIsOtpVisible} user={user}/>
                    )}
                </AnimatePresence>
                <AnimatePresence
                    initial={false}
                    mode={'wait'}
                    onExitComplete={() => null}
                >
                    {isCreatePaymentModalOpen && (
                        <CreatePaymentDetailModal user={user} setVisible={setIsCreatePaymentModalOpen} />
                    )}
                </AnimatePresence>
                <AnimatePresence
                    initial={false}
                    mode={'wait'}
                    onExitComplete={() => setInsufficientBalance(false)}
                >
                    {insufficientBalance && (
                        <BalanceInsufficientModal setVisible={setInsufficientBalance} />
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default TransactionHistory;
