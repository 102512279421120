import React from "react";
import DataTable from "react-data-table-component";
import { FaTimes, FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const TransactionDataTable = ({ transactionData }) => {
    const { t } = useTranslation();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  return (
    <>
      <h2 className="transaction_title">{t('transactions.details.title')}</h2>
      <DataTable
        columns={[
          {
            name: (
              <div className="row_item_stat row_item_header_stat">
                {t('transactions.details.id')}
              </div>
            ),
            selector: (row) => <div className="row_item_stat">{row.id}</div>,
            sortable: true,
            center: true,
          },
          {
            name: (
              <div className="row_item_stat row_item_header_stat">
                {t('transactions.details.amountEarned')}
              </div>
            ),
            selector: (row) => (
              <div className="row_item_stat">{row.amount_earned}</div>
            ),
            sortable: true,
            center: true,
          },
          {
            name: (
              <div className="row_item_stat row_item_header_stat">
                {t('transactions.details.withdrawStatus')}
              </div>
            ),
            selector: (row) => (
              <div className="row_item_stat">
                {row.is_withdrawn === false ? (
                  <FaTimes color="red" width={20} height={20} />
                ) : (
                  <FaCheck color="green" width={20} height={20} />
                )}
              </div>
            ),
            sortable: true,
            center: true,
          },
          {
            name: (
              <div className="row_item_stat row_item_header_stat">
                {t('transactions.details.createdOn')}
              </div>
            ),
            selector: (row) => (
              <div className="row_item_stat">{formatDate(row.created)}</div>
            ),
            sortable: true,
            center: true,
          },
          {
            name: (
              <div className="row_item_stat row_item_header_stat">
             {t('transactions.details.availableOn')}
              </div>
            ),
            selector: (row) => (
              <div className="row_item_stat">
                {formatDate(row.available_date)}
              </div>
            ),
            sortable: true,
            center: true,
          },
        ]}
        data={transactionData}
        pagination
        striped
        selectableRowsHighlight
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15]}
        highlightOnHover
        fixedHeader
        fixedHeaderScrollHeight="400px"
        responsive
      />
    </>
  );
};

export default TransactionDataTable;
