import React, { useEffect, useLayoutEffect, useState } from 'react'
import Carousel from 'react-elastic-carousel';
import CarouselItem from './CarouselItem';
import Art from '../../../../Assets/img/landing/category/art.jpg'
import Classics from '../../../../Assets/img/categories/chrome-drive.webp'
import Biography from '../../../../Assets/img/categories/woman-reading.webp'
import History from '../../../../Assets/img/categories/elevated-view.webp'
// import { useStats } from 'react-instantsearch';
import Psychology from '../../../../Assets/img/categories/human-brain.webp';
import Entrepreneur from '../../../../Assets/img/categories/soloprenuer.webp';
import Computer from '../../../../Assets/img/categories/computer.webp';
import Engineering from '../../../../Assets/img/categories/engineering.webp';
import Medecine from '../../../../Assets/img/categories/close-up.webp';
import Finance from '../../../../Assets/img/categories/man-calculating-monthly-savings.webp';
// import Romance from '../../../../Assets/img/landing/category/'

const breakPoints = [
    { width: 1, itemsToShow: 1.1 },
    { width: 200, itemsToShow: 1.4 },
    { width: 270, itemsToShow: 1.8 },
    { width: 320, itemsToShow: 2.4, itemsToScroll: 2 },
    { width: 380, itemsToShow: 2.6},
    { width: 430, itemsToShow: 3 },
    { width: 450, itemsToShow: 3.4 },
    { width: 550, itemsToShow: 4 },
    { width: 600, itemsToShow: 5 }
  ];

const CategoryCarousel = ({ categories }) => {
    const [categoryList, setCategoryList] = useState(categories)
    const catPlugin = [
        {
            name: "Culture",
            color: '#B1E9CD',
            img: Art
        },
        {
            name: "Biography",
            color: '#D7F4E4',
            img: Biography
        },
        {
            name: "Médecine",
            color: '#DBA48A',
            img: Medecine
        },
        {
            name: "Mathématique",
            color: '#DBA48A',
            img: Classics
        },
        {
            name: "Finance",
            color: '#D7F4E4',
            img: Finance
        },
        {
            name: "Engineering",
            color: '#D26465',
            img: Engineering
        },
        {
            name: "Histoire",
            color: '#E2D9EC',
            img: History
        },
        {
            name: "Informatique",
            color: '#FBD0B5',
            img: Computer
        },
        {
            name: "Entreprenariat",
            color: '#F9E6BC',
            img: Entrepreneur
        },
        {
            name: "Psychologie",
            color: '#D26465',
            img: Psychology
        },
    ]
    
    
    
    return (
        <div className='carouselContainer_category'>
            <Carousel 
                autoPlay infiniteLoop interval={1000} 
                showArrows={false}
                breakPoints={breakPoints}
                enableAutoPlay={true}
                className="carouselWrapper_category"
                isRTL
            >

            {
                catPlugin.map((cat) => (
                    <CarouselItem color={cat.color} name={cat.name} img={cat.img} key = {cat?.pk} draggable="false" />    
                ))
            }
            </Carousel>
        </div>
    )
}

export default CategoryCarousel
