import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const routeSeoConfig = {
  register: {
    title: "Register | Welcome to hooyia",
    description:
      "Create your hooyia account and start your journey to financial growth. Simple, secure registration process.",
    keywords:
      "register hooyia, e-book, e-learning",
    ogTitle: "Register for HooYia",
    ogDescription:
      "Start your learning and financial growth journey. Create your account in minutes.",
    canonicalUrl: "https://www.e-hooyia.com/register",
  },
  
};

export const updateMetaTags = ({
  title,
  description,
  keywords,
  ogTitle,
  ogDescription,
  ogImage,
  twitterTitle,
  twitterDescription,
  twitterImage,
  canonicalUrl,
  noindex = false,
}) => {
  console.log("Updating meta tags====================????????", title);

  document.title = title;

  const updateMetaTag = (selector, content, attr = "name") => {
    const tag = document.querySelector(`meta[${attr}="${selector}"]`);
    if (tag) {
      tag.setAttribute("content", content);
    } else {
      const newTag = document.createElement("meta");
      newTag.setAttribute(attr, selector);
      newTag.setAttribute("content", content);
      document.head.appendChild(newTag);
    }
  };

  if (description) {
    updateMetaTag("description", description);
  }
  if (keywords) {
    updateMetaTag("keywords", keywords);
  }

  if (ogTitle) {
    updateMetaTag("og:title", ogTitle, "property");
  }
  if (ogDescription) {
    updateMetaTag("og:description", ogDescription, "property");
  }
  if (ogImage) {
    updateMetaTag("og:image", ogImage, "property");
  }

  // Twitter tags
  if (twitterTitle) {
    updateMetaTag("twitter:title", twitterTitle);
  }
  if (twitterDescription) {
    updateMetaTag("twitter:description", twitterDescription);
  }
  if (twitterImage) {
    updateMetaTag("twitter:image", twitterImage);
  }

  let canonicalTag = document.querySelector('link[rel="canonical"]');
  if (canonicalUrl) {
    if (canonicalTag) {
      canonicalTag.setAttribute("href", canonicalUrl);
    } else {
      canonicalTag = document.createElement("link");
      canonicalTag.setAttribute("rel", "canonical");
      canonicalTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(canonicalTag);
    }
  }

  if (noindex) {
    updateMetaTag("robots", "noindex, nofollow");
  }
};

export const useSEO = (seoData) => {
  const location = useLocation();

  const updateSEO = useCallback(() => {
    if (seoData) {
      updateMetaTags(seoData);
    }
  }, [seoData]);

  useEffect(() => {
    updateSEO();
    return () => {
      document.title = "HooYia e-learning system";
    };
  }, [location.pathname, updateSEO]); 
};

export const withSEO = (WrappedComponent, getMetaData) => {
  return (props) => {
    const location = useLocation();
    const seoData =
      typeof getMetaData === "function" ? getMetaData(props) : getMetaData;

    useSEO(seoData);

    return <WrappedComponent {...props} />;
  };
};
