import React from 'react';
import {FaCreditCard, FaHandHoldingUsd, FaMoneyBill, FaArrowRight } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const Header = ({ setActiveTable, activeTable, initialData }) => {
    const { t } = useTranslation();
    const onSubmit = () => {
        setActiveTable((activeTable) => !activeTable);
    }
    return (
        <div className="transaction_grid">
            <div className="grid_item">
                <div className="grid_item_card">
                    <div className="card_item_title">
                        <div className="item-icon">
                            <FaMoneyBill color="fff"/>
                        </div>
                        <h2>{t('transactions.balance.title')}</h2>
                    </div>
                    <div className="card_details">
                        <span>{t('transactions.balance.available')}</span>
                        <p>{initialData.available_to_withdraw} XAF</p>
                    </div>
                </div>
            </div>
            <div className="grid_item">
                <div className="grid_item_card">
                    <div className="card_item_title">
                        <div className="item-icon">
                            <FaHandHoldingUsd color="fff"/>
                        </div>
                        <h2>{t('transactions.upcoming.title')}</h2>
                    </div>
                    <div className="card_details">
                        <span>{t('transactions.upcoming.period')}</span>
                        <p>{initialData.upcoming} XAF</p>
                    </div>
                </div>
            </div>
            <div className="grid_item">
                <div className="grid_item_card">
                    <div className="card_item_title">
                        <div className="item-icon">
                            <FaCreditCard color="fff"/>
                        </div>
                        <h2>{t('transactions.sent.title')}</h2>
                    </div>
                    <div className="card_details">
                            <span>{t('transactions.sent.update')}</span>
                            <p>{initialData.total_withdrawn} XAF <a onClick={onSubmit}>{ activeTable === true ? `${t('transactions.history.title')}` : `${t('transactions.details.title')}`}<FaArrowRight width={10} height={10} color="#fff"/></a></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;