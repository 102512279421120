import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import './Nav.css'
import './NavDesktop.css'
import { RxHamburgerMenu } from 'react-icons/rx'
import { RiUser6Line, RiSearch2Line } from 'react-icons/ri'
import { FiShoppingCart } from 'react-icons/fi'
import { BsShift } from 'react-icons/bs'
import { TbHome } from 'react-icons/tb'
import { BsChevronRight } from 'react-icons/bs'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import { IoPersonOutline } from 'react-icons/io5'
import { IoIosLogOut, IoMdHeartEmpty } from 'react-icons/io'
import { BiShareAlt, BiSupport } from 'react-icons/bi'
import { BsGlobe, BsPhone, BsGear } from 'react-icons/bs'
import { MdOutlinePublishedWithChanges } from 'react-icons/md'
import { AiFillCaretDown } from 'react-icons/ai'
import { RiSwitchFill } from 'react-icons/ri'

import { BsFacebook } from 'react-icons/bs'
import { BsWhatsapp } from 'react-icons/bs'
import { BsTwitter } from 'react-icons/bs'
import { BsLinkedin } from 'react-icons/bs'
import { BsTiktok } from 'react-icons/bs'
import { BsInstagram } from 'react-icons/bs'
import { BsTelegram } from 'react-icons/bs'
import { TbBrandGmail } from 'react-icons/tb'
import { Link, useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import 'flag-icon-css/css/flag-icon.min.css'
import Logo from '../../Assets/img/logo512.png';



import LinkImage from './LinkImage'
import Women from '../../Assets/img/41pOKvNMdnL._SX355_BO1,204,203,200_.jpg'
import Watch from '../../Assets/img/landing/category/fiction.jpg'
import Bag from '../../Assets/img/landing/category/middle_addult.jpg'
import Men from '../../Assets/img/HooYia.png'
import Education from './book.jpg'
import Sport from '../../Assets/img/landing/category/cook.jpg'
import Appliances from '../../Assets/img/landing/category/marshal-quast-v69MsNF7FAU-unsplash.jpg'
import Jewelry from '../../Assets/img/landing/category/middle_addult.jpg'
import Automotive from '../../Assets/img/HooYia.png'


import CurrencySelect from '../Addons/CurrencySelect';
import { UserContext } from '../../Context/AuthContext';
import { IsSellercontext } from '../../Context/IsSellerContext';
import useLocalStorage from '../lib/UseLocalStorage'
import { CartContext } from '../../Context/CartContext';
import { SellerReloadContext } from '../../Context/SellerReload';
import { Hits, InstantSearch, RefinementList, SearchBox } from 'react-instantsearch';
import { searchClient } from '../../searchClient';
import { getRequest } from '../../Resquest';

const Nav = ({ category_list, handleChangeLanguage, handleRemoveAuthModal }) => {
        const { t } = useTranslation()
        const [user, setUser] = useContext(UserContext);
        const [searchString, setSearchString] = useState('');
        const [searchStringMobile, setSearchStringMobile] = useState('');
        const [showNav, setShowNav] = useState(false);
        const [showSubNavLang, setShowSubNavLang] = useState(false);
        const [isSellerAccount, setIsSellerAccount] = useContext(IsSellercontext);
        const [sellerReload, setSellerReload] = useContext(SellerReloadContext)
        const navigate = useNavigate()
        const [cartList, setCartList] = useContext(CartContext)
        const [currency, setCurrency] = useState('XAF')
        const [isSearching, setIsSearching] = useState(false)
        const [stringMobile, setStringMobile] = useState(false)
	const [recommendations, setRecommendations] = useState([])
        const [openShareLinks, setOpenShareLinks] = useState(false);
        const [languages, setLanguages] = useState([
                {
                name: "English",
                code: 'en',
                flag: 'gb'
                },
                {
                name: "Français",
                code: 'fr',
                flag: 'fr'
                }
        ]);

        useEffect(() => {
          getPopularBooks()
        }, [])

        const getPopularBooks = async() => {
                const response = await getRequest(`/public/ebook/display/?limit=4`)
		response?.status === 200 && setRecommendations(response?.data?.results)
        }
        

        const removeNav = (e)=>{
                if(e.target.className === 'navLink'){
                        setShowNav(false)
                }
        }

        const sellOnHooYia = () => {
                
        }

        const startSelling = () => {
                if (user?.user.is_seller_account) {
                        navigate('/')
                        setIsSellerAccount(true) 
                } else {
                        navigate('/seller-onboarding')
                }
        }

        const switchToSelling = () => {
                if (user?.user.is_seller_account) {
                        navigate('/', {state: true})
                        setSellerReload(true)
                        setIsSellerAccount(true) 
                }else {
                        navigate('/seller-onboarding')
                }
       }

       const changeCurrency = (currency) => {
                setCurrency(currency.label)
       }

        const navLinks = [Women,  Watch, Bag, Men, Education, Sport, Appliances, Jewelry, Automotive, Watch, Bag, Men, Education, Sport, Appliances, Jewelry, Automotive]
                
        const queryHook = (query, search) => {
                search(query);
                query.length > 0 ? setIsSearching(true) : setIsSearching(false)
        };

        const queryHookMobile = (query, search) => {
                search(query);
                query.length > 0 ? setStringMobile(true) : setStringMobile(false)
        };

        const removeSearch = (e) => {
                e.target.className === 'search_result_list' && setIsSearching(false)
        }

        const goto_page = (page_cat) => {
                navigate('/ebooks?category='+ page_cat?.name, {state: {"dir": page_cat?.name}})
                setShowNav(false)
        }

        const handleShare = async () => {

                if (navigator.share) {
                  try {
                    await navigator.share({
                      title: t('shareLinks.shareLink_title'),
                      text: t('sharelinks.shareLink_description'),
                      url: window.location.href, // Récupère le lien actuel
                    });
                  } catch (error) {
                  }
                } else {
                  setOpenShareLinks(!openShareLinks)
                }
        };
        const shareLinks = {
                facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`,
                whatsapp: `https://wa.me/?text=${encodeURIComponent(window.location.href)}`,
                telegram: `https://t.me/share/url?url=${encodeURIComponent(window.location.href)}&text=${t('user_nav.mobile.shareLink_title')}`,
                instagram: `https://www.instagram.com/?url=${encodeURIComponent(window.location.href)}`, // Instagram n'a pas d'URL de partage direct
                twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${t('user_nav.mobile.shareLink_title')}`,
                linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`,
                gmail: `https://mail.google.com/mail/?view=cm&to=&su=${t('shareLinks.shareLink_title')}&body=${encodeURIComponent(window.location.href)}`, // Ouvre Gmail
                tiktok: `https://www.tiktok.com/share?url=${encodeURIComponent(window.location.href)}`, // TikTok, si pris en charge
                email: `mailto:?subject=${t('shareLinks.shareLink_title')}&body=${encodeURIComponent(window.location.href)}`,
        };

  return (

        <InstantSearch searchClient={ searchClient } indexName="hooyia_index_ebook" insights={true}>
        <div className='navWrapper'>
                <div className='container'>
                        <div className='navBar navMobileFix'>
                        <div className='navMobile'>
                                <div>
                                        <div className='mobile-top'>
                                                <div className='logo-burger center'>
                                                        <span onClick={() => setShowNav(true)} className='burger nav-icon'>
                                                                <RxHamburgerMenu />
                                                        </span>
                                                        <Link to="/">
                                                                <span className="logo-base">HooYia</span>
                                                        </Link>
                                                </div>
                                                <div>
                                                        <Link to={`/${user?.user?.first_name}`} className="nav-icon" >
                                                                <RiUser6Line />
                                                        </Link>
                                                        <Link to='/cart' className="nav-icon shoping_cart_mobile" >
                                                                <FiShoppingCart />
                                                                <span className='mobile_cart_count'>{ cartList.length }</span>
                                                        </Link>

                                                </div>
                                        </div>
                                        <div className='search'>
                                                <SearchBox queryHook={ queryHookMobile } className='search_n_user mobile_search_user' placeholder={t("user_nav.search")} />
                                        </div>
                                        {
                                                stringMobile && (
                                                        <div className='search_results_mobile'>
                                                                <Hits hitComponent={HitMobile} />
                                                        </div>
                                                )
                                        }
                                </div>
                                <div className={`nav-mobile ${showNav ? 'showNav': ''}`}>
                                        <div className={`shadow ${showNav && 'showShadow'}`} onClick={() => setShowNav(false)} />
                                        <div className='nav-content' >
                                                <div className='logo-burger nav-inside-logo'>
                                                        <span className='burger nav-icon'>
                                                                <TbHome />
                                                        </span>
                                                        <Link to="/">
                                                                <span className="logo-base">HooYia</span>
                                                        </Link>
                                                </div>
                                                <div onClick={ removeNav }  className='nav-list'>
                                                        
                                                        
                                                        <div className='nav-head'>
                                                                <div className='title'>{ t("user_nav.mobile.shopping") }</div>
                                                                <div className='icon'><HiChevronRight /></div>
                                                        </div>
                                                        <ul className='navLinks'>
                                                                {
                                                                recommendations.map((recommendation, index) => (
                                                                <li key={index}>
                                                                        <Link className='navLink' to={`/ebooks/${recommendation.slug}`}>
                                                                                <LinkImage image={ recommendation?.cover } />
                                                                                { recommendation?.name && <span>{ recommendation?.name.substring(0, 40) }...</span> }
                                                                        </Link>
                                                                </li>                                                                        
                                                                ))
                                                                }
                                                        </ul>
                                                        <div className='nav-head'>
                                                                <div className='title'>{ t("user_nav.mobile.configuration") }</div>
                                                                <div className='icon'><HiChevronRight /></div>
                                                        </div>
                                                        <div className='mobile-slide-right'>
                                                                <ul className='navLinks'>
                                                                        <li>
                                                                                <Link className='navLink' to= '/my-books'>
                                                                                        <span className='icon lang_globe'><BsPhone /> </span>
                                                                                        <span>{ t("user_nav.mobile.my_books") }</span>
                                                                                </Link>
                                                                        </li>
                                                                        <li>
                                                                                <Link className='navLink' to={`/${ user?.user?.first_name }/settings`}>
                                                                                        <span className='icon lang_globe'><BsGear /> </span>
                                                                                        <span>{ t("user_nav.mobile.setting") }</span>
                                                                                </Link>
                                                                        </li>
                                                                        <li>
                                                                                <Link className='navLink' to= '/products/watches'>
                                                                                        {/* <LinkImage image="" /> */}
                                                                                        <div className={`mobile-slide-right-item ${showSubNavLang ? 'show_sub_nav_lang' : ''}`}>
                                                                                                <div className='nav-head'>
                                                                                                        <div className='title'>{ t("user_nav.mobile.language") }</div>
                                                                                                        <div onClick={() =>setShowSubNavLang(false)} className='icon remove_slide'><HiChevronLeft /></div>
                                                                                                </div>
                                                                                                {
                                                                                                        languages.map((language, index) => (
                                                                                                        <p key={index} className="dropdownNav-item">
                                                                                                                <span  onClick={(e) => handleChangeLanguage(language.code, e)} className={`flag-icon flag-icon-${language.flag}`}></span>
                                                                                                                <span  onClick={(e) => handleChangeLanguage(language.code, e)} className='blacktext'>{language.name}</span>
                                                                                                        </p>
                                                                                                        ))
                                                                                                }
                                                                                        </div>
                                                                                        <div className='lang_slide_nav' onClick={() =>setShowSubNavLang(true)}>
                                                                                                <span className='icon lang_globe'><BsGlobe /> </span>
                                                                                                <span>{ t("user_nav.mobile.language") }</span>
                                                                                        </div>
                                                                                </Link>
                                                                        </li>
                                                                        <li>
                                                                                <Link className='navLink' to= '/products/watches'>
                                                                                        {/* <LinkImage image="" /> */}
                                                                                        <div className={`mobile-slide-right-item ${showSubNavLang ? 'show_sub_nav_lang' : ''}`}>
                                                                                                <div className='nav-head'>
                                                                                                        <div className='title'>{ t("user_nav.mobile.language") }</div>
                                                                                                        <div onClick={() =>setShowSubNavLang(false)} className='icon remove_slide'><HiChevronLeft /></div>
                                                                                                </div>
                                                                                                {
                                                                                                        languages.map((language, index) => (
                                                                                                        <p key={index} className="dropdownNav-item">
                                                                                                                <span  onClick={(e) => handleChangeLanguage(language.code, e)} className={`flag-icon flag-icon-${language.flag}`}></span>
                                                                                                                <span  onClick={(e) => handleChangeLanguage(language.code, e)} className='blacktext'>{language.name}</span>
                                                                                                        </p>
                                                                                                        ))
                                                                                                }
                                                                                        </div>
                                                                                        <div className='lang_slide_nav' onClick={() =>setShowSubNavLang(true)}>
                                                                                                <span className='icon lang_globe'><BiSupport /> </span>
                                                                                                <span>{ t("user_nav.mobile.help") }</span>
                                                                                        </div>
                                                                                </Link>
                                                                        </li>
                                                                        <li>
                                                                                <Link className='navLink' to= '/' onClick={handleShare}>
                                                                                        <span className='icon lang_globe'><BiShareAlt /> </span>
                                                                                        <span>{ t("user_nav.mobile.invite") }</span>
                                                                                </Link>

                                                                                {openShareLinks && (<>
                                                                                                <span className='shareOn'>{t('shareLinks.share_on')}</span>
                                                                                                <div className="shareLinks">
                                                                                                        <a target="_blank" rel="noopener noreferrer" href={shareLinks.facebook}>
                                                                                                                <BsFacebook className='shareIcon'></BsFacebook>
                                                                                                        </a>
                                                                                                        <a target="_blank" rel="noopener noreferrer" href={shareLinks.whatsapp}>
                                                                                                                <BsWhatsapp className='shareIcon'></BsWhatsapp>
                                                                                                        </a>
                                                                                                        <a target="_blank" rel="noopener noreferrer" href={shareLinks.twitter}>
                                                                                                                <BsTwitter className='shareIcon'></BsTwitter>
                                                                                                        </a>
                                                                                                        <a target="_blank" rel="noopener noreferrer" href={shareLinks.linkedin}>
                                                                                                                <BsLinkedin className='shareIcon'></BsLinkedin>
                                                                                                        </a>
                                                                                                        <a target="_blank" rel="noopener noreferrer" href={shareLinks.tiktok}>
                                                                                                                <BsTiktok className='shareIcon'></BsTiktok>
                                                                                                        </a>
                                                                                                        <a target="_blank" rel="noopener noreferrer" href={shareLinks.instagram}>
                                                                                                                <BsInstagram className='shareIcon'></BsInstagram>
                                                                                                        </a>
                                                                                                        <a target="_blank" rel="noopener noreferrer" href={shareLinks.telegram}>
                                                                                                                <BsTelegram className='shareIcon'></BsTelegram>
                                                                                                        </a>
                                                                                                        <a target="_blank" rel="noopener noreferrer" href={shareLinks.gmail}>
                                                                                                                <TbBrandGmail className='shareIcon'></TbBrandGmail>
                                                                                                        </a>
                                                                                                </div>
                                                                                        </>)
                                                                                }
                                                                        </li>
                                                                        <li onClick={() => switchToSelling(true)}>
                                                                                <span className='navLink' >
                                                                                        <span className='icon lang_globe'><MdOutlinePublishedWithChanges /> </span>
                                                                                        <span>{ t("user_nav.mobile.switch") }</span>
                                                                                </span>
                                                                        </li>
                                                                        {
                                                                        user?.user ? (
                                                                                <li>
                                                                                        <Link onClick={ handleRemoveAuthModal } className='navLink logoutNavLink' to= '/products/clothes/women'>
                                                                                                <LinkImage image="" />
                                                                                                <span>{ t("user_nav.account.logout") }</span>
                                                                                        </Link>
                                                                                </li>

                                                                        ) : (
                                                                                <li>
                                                                                        <Link className='navLink logNavLink' to= '/products/clothes/women'>
                                                                                                <LinkImage image="" />
                                                                                                <span>Signup / Login</span>
                                                                                        </Link>
                                                                                </li>

                                                                        )   
                                                                        }
                                                                </ul>
                                                        </div>
                                                        <hr />
                                                        <div className='nav-head'>
                                                                <div className='title'>{ t("user_nav.mobile.popular") }</div>
                                                                <div className='icon'><HiChevronRight /></div>
                                                        </div>
                                                        <ul className='navLinks'>
                                                                {
                                                                        category_list.map((cat, index) => (
                                                                        <li key={index}>
                                                                                <a className='navLink' onClick={()=>goto_page(cat) }>
                                                                                        <LinkImage image={navLinks[index]} />
                                                                                        <span>{cat.name}</span>
                                                                                </a>
                                                                        </li>

                                                                        ))
                                                                }
                                                        </ul>
                                                </div>
                                        </div>
                                </div>
                        </div>

                        {/* ================Nav desktop================= */}
                        <nav>
                                <div className='top'>
                                        
                                        <div className='right'>
                                                <Link to="/">
                                                        <img src={Logo} width={50} height={50} alt="hooyia-logo"/><span className="logo-base">HooYia</span>
                                                </Link>
                                        </div>
                                        <ul className='topLinks'>
                                                <li>
                                                        <div className='linkTopWrapper dropdown_anchore'>
                                                                <span className='linkTop' onClick={sellOnHooYia}>{ t("user_nav.sell.title") }</span>
                                                                <span className='iconB'>
                                                                        <AiFillCaretDown />
                                                                </span>
                                                                <div className='dropdownNav'>
                                                                        <div onClick={() => startSelling()} className='dropdownNav-item'>{ t("user_nav.sell.start_selling") }</div>
                                                                        <div onClick={() => switchToSelling()} className='dropdownNav-item'><span>{ t("user_nav.sell.switch") }</span></div>
                                                                </div>
                                                        </div>
                                                </li>
                                                <li>
                                                        <div className='linkTopWrapper dropdown_anchore' onClick={()=> navigate('/faqs')} >
                                                                <span className='linkTop' >{ t("user_nav.help.title") }</span>
                                                           {/*  <span className='iconB'>
                                                                        <AiFillCaretDown />
                                                                </span>
                                                                <div className='dropdownNav'>
                                                                        <div onClick={()=> navigate('/faqs')} className='dropdownNav-item'>{ t("user_nav.help.hc") }</div>
                                                                        <div onClick={()=> navigate('/faqs')} className='dropdownNav-item'>{ t("user_nav.help.faq") }</div>
                                                                        <div className='dropdownNav-item'>{ t("user_nav.help.tas") }</div>
                                                                        <div className='dropdownNav-item'>{ t("user_nav.help.soh") }</div>
                                                                </div> */}
                                                        </div>
                                                </li>
                                                 <li>
                                                        <div className='linkTopWrapper' onClick={()=> navigate('/refund-policy')}>
                                                                <span className='linkTop' onClick={sellOnHooYia}>{ t("user_nav.buyer_protection") }</span>
                                                        </div>
                                                </li>

                                                 <li>    
                                                        <div className='linkTopWrapper dropdown_anchore'>
                                                                <span className='linkTop' onClick={sellOnHooYia}>{ currency } / <span className={`flagSpace flag-icon flag-icon-${ t("language") === 'Francais'? 'fr': 'gb' }`} />{ t("language") }</span>
                                                                <span className='iconB'>
                                                                        <AiFillCaretDown />
                                                                </span>
                                                                <div className='dropdownNav'>
                                                                        <div className='bold subtitle'>{ t("language") }</div>
                                                                        <div className='subDropDown language_dropdown'>
                                                                                {
                                                                                        languages.map((language, index) => (
                                                                                        <p key={index} className="dropdownNav-item">
                                                                                                <span  onClick={(e) => handleChangeLanguage(language.code, e)} className={`flag-icon flag-icon-${language.flag}`}></span>
                                                                                                <span  onClick={(e) => handleChangeLanguage(language.code, e)} className='blacktext'>{language.name}</span>
                                                                                        </p>
                                                                                        ))
                                                                                }
                                                                        </div>
                                                                        {/* Currency selection */}
{/* 
                                                                        <div className='bold subtitle'>Currency</div>
                                                                        <div className='subDropDown'>
                                                                                <CurrencySelect handleChangeCurrency={ changeCurrency } />
                                                                        </div> */}
                                                                </div>
                                                        </div>
                                                </li>
                                                <li>
                                                        <div className="linkTopWrapper">
                                                                <div className='dropdownNav-item switch_to_selling_btn' onClick={()=>{switchToSelling()}}>
                                                                        <span> <BsShift></BsShift>  </span>{ t("user_nav.account.switch") }
                                                                </div>
                                                        </div>
                                                </li>
                                        </ul>
                                </div>
                                <div className='nav-main'>

                                                        {/* <Hits /> */}
                                        <div className='searchWrapper'>
                                                <div className='user_search_wrapper'>
                                                        <SearchBox queryHook={queryHook} className='user_search search' placeholder={t("user_nav.search_ebook")} />
                                                        <RefinementList attribute="name" /> 
                                                        <span className='searchIcon'>
                                                                <RiSearch2Line />
                                                        </span>
                                                </div>
                                                        {
                                                                isSearching && (
                                                                <div onClick={e => removeSearch(e)} className='searchResults'>
                                                                        <div className='user_main_hit_shadow' onClick={() => setIsSearching()} />
                                                                        <Hits hitComponent={Hit} />
                                                                </div>
                                                                )
                                                        }
                                        </div>

                                        {/* cart icon */}
                                        <Link to='/cart' className='cartBtn'>
                                                <span className='shoppingGlyphe'>
                                                        <FiShoppingCart/>
                                                </span>
                                                <span className='cart-count'>{cartList?cartList.length:0}</span>
                                        </Link>

                                        {/* menu */}
                                        <ul className='topLinks second-menu'>
                                                <li>
                                                        <Link to='/wishList' className='linkTopWrapper'>
                                                                <span className='iconB iconApp'>
                                                                        <IoMdHeartEmpty />
                                                                </span>
                                                                <span className='linkTop' onClick={sellOnHooYia}>{ t("user_nav.wish_list") }</span>
                                                        </Link>
                                                </li>
                                                <li>
                                                        <div onClick={()=> navigate('/my-books')} className='linkTopWrapper'>
                                                                <span className='iconB iconApp'>
                                                                        <BsPhone  />
                                                                </span>
                                                                <span className='linkTop' onClick={sellOnHooYia}>{ t("user_nav.my_books") }</span>
                                                        </div>
                                                </li>
                                                <li>
                                                        <div className='linkTopWrapper dropdown_anchore'>
                                                                <span className='iconB iconApp'>
                                                                        <IoPersonOutline />
                                                                </span>
                                                                <span className='linkTop' onClick={sellOnHooYia}>{ t("user_nav.account.title") }</span>
                                                                <div className='dropdownNav'>
                                                                        <div className='dropdownNav-item' onClick={()=> navigate(`/${ user?.user?.first_name }`)}>{ t("user_nav.account.profile") }</div>
                                                                        <div onClick={() => navigate('my-books')} className='dropdownNav-item'>{ t("user_nav.account.my_books") }</div>
                                                                        <div className='dropdownNav-item' onClick={()=> navigate(`/${ user?.user?.first_name }/settings`)}>{ t("user_nav.account.setting") }</div>
                                                                        <div onClick={ handleRemoveAuthModal } className='dropdownNav-item logoutNavLink'>
                                                                                <span className='accountIcon'>
                                                                                        <IoIosLogOut />
                                                                                </span>
                                                                                { t("user_nav.account.logout") }
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </li>
                                        </ul>
                                </div>
                        </nav>
                        </div>
                </div>
                
        </div>
        </InstantSearch>
  )
}

export default Nav

const Hit = ({ hit }) => {
        const navigate = useNavigate()
        const gotoPage = () => {
                navigate(`/ebooks/${hit?.objectID}`)
                window.location.reload()
        }
        return (
                <div onClick={gotoPage} className='search_result_list'>
                        <div className='result_img'>
                                <img src={'https://e-hooyia.s3.amazonaws.com/' + hit?.cover} alt={hit?.name} />
                        </div>
                        <div className='result_desc'>
                                <p>{ hit?.name }</p>
                                <p>{ hit?.description }</p>
                        </div>
                </div>
        )
}
const HitMobile = ({ hit }) => {
        const navigate = useNavigate()
        const gotoPage = () => {
                navigate(`/ebooks/${hit?.objectID}`)
                window.location.reload()
        }
        return (
                <div onClick={gotoPage} className='search_result_mobile'>{ hit?.name }</div>
        )
}