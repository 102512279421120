import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import ReactCrop from 'react-image-crop'
import './Book.css'
import LanguageSelect from '../../Addons/LanguageSelect'
import { VscChromeClose } from 'react-icons/vsc'

import { auth, getRequest, patchRequest, postFetchRequest, postRequest } from '../../../Resquest'
import CategorySelect from '../../Addons/CategorySelect'
import { useLocation, useNavigate } from 'react-router-dom'
import { CiImageOn } from 'react-icons/ci'
import { BsCardImage, BsFileEarmarkMedicalFill } from 'react-icons/bs'
import PopupAddToAlert from '../../Addons/PopupAddToAlert'
import { ReactComponent as Loader } from './LoaderOval.svg'
import { toast } from 'sonner'
const BookCreate = ({ user }) => {
	const [title, setTitle] = useState('');
	const [category, setCategory] = useState('');
	const [price, setPrice] = useState(0);
	const [description, setDescription] = useState('');
	const [author, setAuthor] = useState('')
	const [language, setLanguage] = useState('');
	const [coverPhoto, setCoverPhoto] = useState();
	const [coverPhUrl, setCoverPhUrl] = useState();
	const [bookFile, setBookFile] = useState('');
	const [serverCategories, setServerCategories] = useState([]);
	const [tags, setTags] = useState([]);
	const [tagString, setTagString] = useState('');
	const [allGood, setAllGood] = useState(false)
	const [formValidated, setFormValidated] = useState(false);
	// const [progress, setProgress] = useState(0);
	const [isloading, setIsloading] = useState(false)
	const [isPatch, setIsPatch] = useState(false)
	const [displayAlert, setDisplayAlert] = useState(false)
	const [alertMessage, setalertMessage] = useState("")
	const [bookManager, setBookManager] = useState('');
	const [ languageClicked, setLanguageClicked] = useState(false)
	const [ categorySelected, setCategorySelected ] = useState(false)
	
	const [TooltipMenuSticky, setTooltipMenuSticky] = useState(true)
	const [scrollUnderNavbar, setScrollUnderNavbar] = useState(false)

	/* form validation conditions  here */
	const [ errors, setErrors] = useState({
		title: "",
		category: "",
		author: "",
		coverPhoto: "",
		bookFile: "",
		tags: "",
		price: "",
		description: ""
	})



	const [crop, setCrop] = useState({
		unit: '%', // Can be 'px' or '%'
		x: 8,
		y: 8,
		width: 85,
		height: 85
	})
	const [previewFile, setPreviewFile] = useState(null)

	const location = useLocation()

	const navigate = useNavigate()
	const { t } = useTranslation()

	useEffect(() => {
		if (location.state) {
			setTitle(location.state.name)
			setCategory(location.state.categories)
			setPrice(location.state.price)
			setDescription(location.state.description)
			location.state.tags && setTags(location.state.tags)
			setCoverPhUrl(location.state.cover)
		}

		requestCategories();

	}, []);

	useEffect(() => {
		const handleScroll = () => {
			const stopPoint = 2000;
			const navbarHeight = 120;
			setTooltipMenuSticky(window.scrollY <= stopPoint)
			setScrollUnderNavbar(window.scrollY > navbarHeight)
		}
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	})
	const inputRef = useRef();

	const requestCategories = async () => {
		const response = await getRequest('/public/list-category/')
		if(response?.data){
			 setServerCategories(response?.data?.results)
		}
		else{
			toast.warning(t('response_handler.refresh'),{
				position: 'bottom-center'
			})
		}
	}
	
	const languageSelect = (lang) => {
		setLanguage(lang.value)
	}

	const selectCategory = (cat) => {
		setCategory(cat.uuid)
		setCategorySelected(true)
	}
	
	const submitForm = async (e) => {
		e.preventDefault()
		setErrors({
			title: "",
			category: "",
			author: "",
			coverPhoto: "",
			bookFile: "",
			tags: "",
			price: "",
			description: ""
		})
		const newErrors = validate();

		if(Object.keys(newErrors).length > 0){
			setErrors(newErrors);
			toast.error(t('response_handler.err_bad_request'),{
				position: 'bottom-center',
				duration: 6000
			})
			return ;
			
		}
		const formData = new FormData()
		formData.append("categories", category)
		formData.append("name", title)
		formData.append("author", author)
		formData.append("language", language)
		formData.append("description", description)
		formData.append("audio_video", "")
		formData.append("price", price)
		formData.append("author", author)
		formData.append("visibility", "V")
		formData.append("tags_list", tags.slice(0, 5))
		coverPhoto && formData.append("cover", coverPhoto, coverPhoto.name)
		bookFile && formData.append("doc", bookFile)
		
		let endpoint

		if (location.state?.name ) {
			endpoint = `https://hooyia.herokuapp.com/api/v1/seller/ebook/owner-ebook/${location.state.slug}/`
			setIsPatch(true)
		} else {
			endpoint = 'https://hooyia.herokuapp.com/api/v1/seller/ebook/owner-ebook/'
			setIsPatch(false)
		}

		const xhr = new XMLHttpRequest();
		isPatch ? xhr.open('PATCH', endpoint, true) : xhr.open('POST', endpoint, true);

		xhr.setRequestHeader('Authorization', 'Bearer ' + user.access);

		xhr.upload.onprogress = (event) => {
			if (event.lengthComputable) {
				const percentComplete = Math.floor((event.loaded / event.total) * 100);
				setIsloading(true)
			}
		};

		xhr.onerror = function () {
			toast.error(t('response_handler.err_network'),
			{
				position: 'bottom-center',
				duration: 6000
			})
		}

		xhr.onload = () => {
			if (xhr.statusText === "Created" || xhr.statusText === "OK") {
				const message =  t("dashboard_bookcreate.alerts.book_created");
				setalertMessage(message);

				message ? setDisplayAlert(true) : setDisplayAlert(false)
				setIsloading(false)
				console.log('value of alert_message : ', alertMessage);
				console.log(alertMessage ? 'alertmessage exists' : 'alertMessage does not exists');		

				setTimeout(() => {
					window.location.href = '/?book-created=true';
				}, 5000);
			} 
			else {
				setalertMessage( t("dashboard_bookcreate.alerts.book_create_error") );
				alertMessage ? setDisplayAlert(true) : setDisplayAlert(false)
				setIsloading(false)
			}
			if(xhr.status === 503){
				toast.error(t('reponse_handler.unavailable'), {
					position: 'bottom-center'
				})
			}
			else if(xhr.status >= 500 && xhr.status < 600){
				toast.error(t('response_handler.server_error'),{
					position: 'bottom-center'
				})
			}
			setIsloading(false);
		};


		xhr.send(formData)
	}

	const validate = () => {
		const newErrors = {}
		if(!(title.trim().length > 1)){
			newErrors.title = t('response_handler.invalid_title')
		}
		else if(!(title.trim().length < 200)){
			newErrors.title = t('response_handler.invalid_title_length')
		}

		if(category.length < 1){
			newErrors.category = t('response_handler.invalid_category')
		}

		if(author.trim().length < 1){
			newErrors.author = t('response_handler.invalid_author') 
		}
		else if(author.trim().length > 300){
			newErrors.author =  t('response_handler.invalid_author_length')
		}

		if(!coverPhoto){
			newErrors.coverPhoto = t('response_handler.invalid_cover_photo')
		}
		if(!bookFile){
			newErrors.bookFile = t('response_handler.invalid_bookFile')
		}
		if((tags?.length <= 4)){
			newErrors.tags = t('response_handler.invalid_tag')
		}
		if(parseInt(price) < 0){
			newErrors.price = t('response_handler.invalid_price')
		}
		else if( parseInt(price) > 150000){
			newErrors.price = t('response_handler.invalid_price_bound')
		}
		if(description.length < 250){
			newErrors.description = t('response_handler.invalid_description')
		}
		return newErrors;
	}
	
	const controlTagList = (e) => {
		e.preventDefault()
		setTags(tags => [...tags, tagString])
		setTagString('')
		inputRef.current.focus();
	}

	const removeTag = (tag) => {
		let filteredTag = tags.filter(tagItem => tag !== tagItem)
		setTags(filteredTag)
	}

	const handleFileInput = (event) => {
		const file = event.target.files[0];
		if (file && file.type.startsWith('image/')) {
			if (file.name.includes(".png") || file.name.includes(".jpeg") || file.name.includes(".jpg")) {
				setCoverPhoto(file)
			}
			else {
				let fname = file.name
				let flen = file.name.length
				let msg = `${t('response_handler.file_extensions')} ${fname[flen-4] == "." ? fname.substring(flen-4) : 
				fname.substring(flen-5)} ${t('response_handler.valid_extensions')}`
				setalertMessage(msg)
				alertMessage ? setDisplayAlert(true) : setDisplayAlert(false)
			}
			// setSelectedImage(URL.createObjectURL(file));
		}
	};

	const handleFileChange = (e) => {
		setBookFile(e.target.files[0])
		const file = e.target.files[0];
    	e.target.files[0] && setPreviewFile(URL.createObjectURL(file));
	}

	const onClose = () => {
		setDisplayAlert(false)
		setalertMessage("")
	}

	const handleTitleChange = (e) => {
		setTitle(e.target.value)
	}

	return (
		<div className='seller_create_book'>
			<div className='container flex_create_book'>
				<div className='create_book_form'>
					<div className='book_title'>{location.state ? `${ t("dashboard_bookcreate.state_edit") } ${location.state.name}` : t("dashboard_bookcreate.state_create") }</div>
					<div className='create_bookWrapper'>
						<form onSubmit={submitForm}>
							{/* {displayAlert && <AlertModal message={"something"} close={onClose}/>} */}
							<PopupAddToAlert showPopup={displayAlert} handleRemoveModal={onClose} message={alertMessage} />
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.title") }</h4>
									<input disabled={title?.length >= 200 ? true : false} onFocus={() => setBookManager('name')} className={`input_seller_text ${title?.length > 0 ? 'input_validated' : ''}`} autoFocus placeholder={t("dashboard_bookcreate.name_of_book")} value={title} onChange={handleTitleChange} />
									<div className='validate_input'>
										<div className='validateStrings'>
											<span className={`invalid ${title.length > 0 && title?.length <= 100 ? 'validated_instruction_text' : ''} `}>{title?.length}/100 { t("dashboard_edit_form.char") }</span>
											<span className='word-wrap'>{errors?.title}</span>
											<span className=''>{ t("dashboard_edit_form.max") }100</span>
										</div>
									</div>
								</div>
							</div>
							
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_bookcreate.author") }</h4>
									<input onFocus={() => setBookManager('author')} className={`input_seller_text ${author?.length > 0 ? 'input_validated' : ''}`} autoFocus placeholder={t("dashboard_bookcreate.author")} value={author} onChange={(e) => setAuthor(e.target.value)} />
									<span className='word-wrap'>{errors?.author}*</span>
								</div>
							</div>
						
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.language") }</h4>
									<div onClick={() => setLanguageClicked(true)} className={` ${languageClicked ? 'more_custom_select' : ''} `}><LanguageSelect handleLanguageSelect={languageSelect} /></div>
								</div>
							</div>

							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.category") }</h4>
									<div className={` ${categorySelected ? 'more_custom_select' : ''} `}>{serverCategories?.length > 1 && <CategorySelect handleCategorySelect={selectCategory} categoryList={serverCategories} className="custom-category-dropdown" />}</div>
									<span className='word-wrap'>{errors?.category}*</span>
								</div>
							</div>


							<h4 className='seller_title'>{ t("dashboard_edit_form.book_cover") }</h4>
							<span className='word-wrap' style={{textAlign: 'left'}}>{errors?.coverPhoto}*</span>
							<div className={`seller_profile_wrapper create_book_media ${bookFile ? 'isCoverMedia' : ''}`}>
								<div className='profile'>
									<div className={`image_picker ${coverPhoto ? 'coverPhotoAvailable ': ''}`}>
										{ coverPhoto ? <div className='remove_cover_photo_btn' onClick={() => setCoverPhoto(null)}>{ t("dashboard_bookcreate.change_photo") }</div>: ''}
										<input
											className='input_seller_text'
											type='file' accept='image/jpeg, image/png, image/gif'
											onChange={e => handleFileInput(e)}
											disabled ={coverPhoto? true: false}
										/>
										<div className='imagePicker_decoy'>
											{
												coverPhoto
													? 
													// <ReactCrop crop={crop}  aspect={1} onChange={c => setCrop(c)}>
														<img src={URL.createObjectURL(coverPhoto)} alt='book_cover'  />
													// </ReactCrop>
													: coverPhUrl 
														? <img src={coverPhUrl} alt='book cover' />
														: <BsCardImage />
											}

										</div>
									</div>
								</div>
							</div>


							<h4 className='seller_title'>{ t("dashboard_edit_form.book_file") }</h4>
							<span className='word-wrap' style={{textAlign: 'left'}}>{errors?.bookFile}*</span>
							<div className='seller_profile_wrapper create_book_media'>
								<div className='profile'>
									<div className='image_picker'>
									{ bookFile ? <div className='remove_cover_photo_btn' onClick={() => setCoverPhoto(null)}>{ t("dashboard_bookcreate.change_file") }</div>: ''}
										<input
											className='input_seller_text'
											type='file' accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,"
											onChange={handleFileChange}
										/>

										{
											!bookFile
												?  <div className='imagePicker_decoy'><BsFileEarmarkMedicalFill /></div>
												:  <div className='imagePicker_decoy'><embed src={previewFile} width='100%' style={{ overflowY: 'hidden' }}  type="application/pdf" /></div>
										}

									</div>
								</div>
							</div>

							
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.tags") } { t("dashboard_bookcreate.tag_sub_desc")  }</h4>
									<span className='word-wrap'>{errors?.tags}*</span>
									<textarea 
										disabled={tags?.length >= 5 ? true : false} 
										className={`input_seller_text ${tags?.length >= 5 ? 'input_validated' : ''}`} 
										ref={inputRef}
										onFocus={() => setBookManager('tag')} 
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												e.preventDefault(); // Empêche le comportement par défaut
												controlTagList(e);  // Ajoute le tag
											}
										}}
										placeholder='Tags*' 
										value={tagString} 
										onChange={e => setTagString(e.target.value)} 
									>
									</textarea>
									<div className='validate_input'>
										<div className='validateStrings'>
											<span className={`invalid ${tags?.length >= 5 ? 'validated_instruction_text' : ''}`}>Completed : {tags?.length} / 5</span>
											<span className=''>{ t("dashboard_edit_form.max") } 5</span>
										</div>
									</div>
								</div>

								<div className='profile_tags'>
									{
										tags?.map(tag => (
											<div key={tag} className='tag'>
												<span className='tag_name'>{tag}</span>
												<span onClick={() => removeTag(tag)} className='tag_remove_btn'><VscChromeClose /></span>
											</div>
										))
									}
								</div>
							</div>


							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.price") }</h4>
									<span className='word-wrap'>{errors?.price}</span>
									<input className= {`input_seller_text ${price >= 0 && price < 150000 ? 'input_validated' : ''}`} 
										onFocus={() => setBookManager('price')}
										value={price}
										onChange={e => setPrice(e.target.value)}
										type="number"
										max={150000}
									/>
									<span className='float_input'>XAF</span>
									<div className='validate_input'>
										<div className='validateStrings'>
											{/* <span className={`invalid`}>Minimun 500</span> */}
											<span className=''>{ t("dashboard_edit_form.max") } 150,000</span>
										</div>
									</div>
								</div>
							</div>


							<div className='seller_profile_wrapper book_forminput_textarea'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.desc") }</h4>
									<span className='word-wrap'>{errors?.description}*</span>
									<textarea className={`book_text_area ${description?.length > 250 ? 'input_validated' : ''} `} placeholder={ t("dashboard_edit_form.desc_body") } value={description} onChange={e => setDescription(e.target.value)}></textarea>
									<span className={`book_description_count mobile ${description?.length >= 250 ? 'validated_instruction_text' : ''}`}>**250 - 50000 { t("dashboard_edit_form.max") }</span>
									<span className={`book_description_count desktop`}>{ t("dashboard_edit_form.min") } {description?.length}/250</span>
								</div>
							</div>
							<div className='button_payment_request'>
								<button disabled={formValidated} className='profile_control_btn create_book_button'>
									<span>{location.state?.name ? t("dashboard_bookcreate.state_edit_book")  : t("dashboard_bookcreate.state_create_book")}</span>
									{ isloading && <span className='book_submit_btn_loader'><Loader /></span> }
								</button>
								{/* {progress > 0 && <progress value={progress} max="100" />}
								{progress > 0 && <span>{progress}%</span>} */}
							</div>
							
						</form>
					</div>

				</div>

				<div className={`saving_book_loader ${isloading ? 'show_book_saving_loader' : ''}`}>
					<div>
						<Loader />
						<p>{ t("dashboard_bookcreate.saving") }</p>
					</div>
				</div>


				<div className='create_book_tooltip create_book_form'>
					<div className={`${!TooltipMenuSticky ? 'no-sticky' : ''} ${scrollUnderNavbar ? 'setTopSticky' : ''}`}>
						<div className='book_title'>{ t("dashboard_bookcreate.create_bm") } </div>
						<h3 className='title book_manager_title'>{ t("dashboard_bookcreate.status") }</h3>
						<div className={`book_create_status ${!allGood ? 'publish_status_not_active' : ''}`}>{ t("dashboard_bookcreate.publish") }</div>
						<div className='status_live'>
							<p>{ t("dashboard_bookcreate.current_status") }</p>
							<span className='top_selling_tag'>{ t("dashboard_bookcreate.status_draft") }</span>
						</div>
						<h3 className='title book_manager_title'>{ t("dashboard_bookcreate.preview") }</h3>
						<div className='book_create_status preview_status'>{ t("dashboard_bookcreate.preview") }</div>
						<div className='tooltip_aside'>
							{
								bookManager === 'name'
									?
									<ToolTipBookCreate />
									: bookManager === 'tag'
									?
									<ToolTipBookCreateTag />
									: bookManager === 'price'
									?
									<ToolTipBookCreatePrice />
									:
									bookManager === 'author'
									?
									<ToolTipAuthor />
									:
									<></>
							}
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

export default BookCreate





const ToolTipBookCreate = () => {
	const { t } = useTranslation()
	return (
		<>
			<div className='input_detail_for_input'>
				<div className='aside_tooltip_wrapper'>
					<div className='detail_head'>{ t("dashboard_bookcreate.aid.title.title") }</div>
					<div className='tooltip_content'>{ t("dashboard_bookcreate.aid.title.desc") }</div>
				</div>
			</div>
		</>
	)
}
const ToolTipAuthor = () => {
	const { t } = useTranslation()
	return (
		<>
			<div className='input_detail_for_input'>
				<div className='aside_tooltip_wrapper'>
					<div className='detail_head'>{ t("dashboard_bookcreate.aid.author.title") }</div>
					<div className='tooltip_content'>{ t("dashboard_bookcreate.aid.author.desc") }</div>
				</div>
			</div>
		</>
	)
}
const ToolTipBookCreateTag = () => {
	const { t } = useTranslation()
	return (
		<>
			<div className='input_detail_for_input'>
				<div className='aside_tooltip_wrapper'>
					<div className='detail_head'>{ t("dashboard_bookcreate.aid.tag.title") }</div>
					<div className='tooltip_content'>{ t("dashboard_bookcreate.aid.tag.desc") }</div>
				</div>
			</div>
		</>
	)
}
const ToolTipBookCreatePrice = () => {
	const { t } = useTranslation()
	return (
		<>
			<div className='input_detail_for_input'>
				<div className='aside_tooltip_wrapper'>
					<div className='detail_head'>{ t("dashboard_bookcreate.aid.price.title") }</div>
					<div className='tooltip_content'>{ t("dashboard_bookcreate.aid.price.desc") }</div>
				</div>
			</div>
		</>
	)
}
