import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./Modal.css";
import { postRequest } from "../../../../Resquest";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

const Modal = ({ setVisible, availableToWithdraw, setOtpVisible, setId }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({ amount: "", password: "" });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    // Form Validation
    const validateForm = () => {
        const errors = {};
        if (!formData.amount) {
            errors.amount = `${t('transactions.modal.amount.error')}`;
        } else if (isNaN(formData.amount) || parseFloat(formData.amount) <= 499 + "XAF" ) {
            errors.amount = `${t('transactions.modal.amount.vaild')}`;
        }

        if (!formData.password) {
            errors.password =  `${t('transactions.modal.password.error')}`;
        }
         else if (parseFloat(formData.amount) > parseFloat(availableToWithdraw)) {
            errors.amount = `${t('transactions.modal.amount.exceed')} ${availableToWithdraw}. XAF`;
        }

        return errors;
    };
    // Handle Input Change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Submit Form
    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
        setIsSubmitting(true);
        setFormErrors({});
        try {
            const response = await postRequest('/seller/withdrawals/request/', formData);
            if (response.status === 201 || response.status === 200) {
                toast.success(`${t('transactions.modal.response.success')}`, {
                    position: "bottom-right",
                });
                console.table("this is the transaction request response", response);
                setId(response.data.id);
                setFormData({ amount: "", password: "" });
                setOtpVisible(true);
                setVisible(false);
            } else if (response.status === 400 && response.response.data.code.includes("insufficient_balance")) {
                toast.error(`${t('transactions.modal.response.insufficient')}`, {
                    position: "bottom-right",
                });
            } else if (response.response?.status === 400 && response.response.data.code.includes("minimum_withdrawal_limit")) {
                toast.error(`${t('transactions.modal.response.withdrawError')}`, {
                    position: "bottom-right",
                });
            } else if (response.response?.status === 400 && response.response.data.code.includes("invalid_password")) {
                toast.error(`${t('transactions.modal.response.passwordError')}`, {
                    position: "bottom-right",
                });
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsSubmitting(false);
        }

    };


    return (
        <div className="panel">
            <motion.div
                className="instructions"
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
            >
        <span className="close" onClick={() => setVisible(false)}>
          &times;
        </span>
                <h2>{t('transactions.modal.title')} <span className="balance">{availableToWithdraw} XAF</span></h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="amount">{t('transactions.modal.amount.title')}</label>
                        <input
                            type="text"
                            id="amount"
                            name="amount"
                            value={formData.amount}
                            onChange={handleChange}
                            inputMode="decimal"
                            disabled={isSubmitting}
                        />
                        {formErrors.amount && <p className="error">{formErrors.amount}</p>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">{t('transactions.modal.password.title')}</label>
                        <div className="password-input">
                            <input
                                type={passwordVisible ? "text" : "password"}
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                disabled={isSubmitting}
                            />
                            <span
                                className="toggle-password"
                                onClick={() => setPasswordVisible(!passwordVisible)}
                            >
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </span>
                        </div>
                        {formErrors.password && (
                            <p className="error">{formErrors.password}</p>
                        )}
                    </div>
                    <button type="submit" className="modal-submit-btn" disabled={isSubmitting}>
                        {isSubmitting ? <span className="loader"></span> : `${t('transactions.withdraw')}`}
                    </button>
                </form>
            </motion.div>
        </div>
    );
};

export default Modal;
