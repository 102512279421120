import React, { useEffect, useState, lazy, Suspense } from 'react'
import NavSeller from '../Components/SELLER/Nav/NavSeller'
import Dashboard from '../Components/SELLER/Dashboard/Dashboard'
import Logout from '../Components/Auth/Logout';
import { Route, Routes } from 'react-router-dom';
import Login from '../Components/Auth/Login';
import SellerProfile from '../Components/SELLER/Dashboard/SellerProfile/SellerProfile';
import SellerProfileEdit from '../Components/SELLER/Dashboard/SellerProfile/SellerProfileEdit';
import Chat from '../Components/mesages/Chat';
import UserProfile from '../Components/Profile/UserProfile';
import Settings from '../Components/Profile/Settings';
import BookCreate from '../Components/SELLER/Book/BookCreate';
import Discount from '../Components/SELLER/Discount/Discount';
import { getRequest } from '../Resquest';
import Earning from '../Components/SELLER/Earnings/Earning';
import InfoSwitchBuying from '../Components/SELLER/InfoSwitchBuying';
import BookDetails from '../Components/SELLER/Book/BookDetails';
import StoreProfile from '../Components/Profile/StoreProfile';
import SellerPrivacy from '../Components/SellerAccount/SellerPrivacy';
import Faq from '../Components/NON-USER/FAQ/Faq';
import Contact from '../Components/NON-USER/Contact/Contact';
import TransactionHistory from '../Components/SELLER/transactionHistory/TransactionHistory';
import RequestSubmitted from '../Components/NON-USER/Contact/RequestSubmitted';
import LazyLoader from '../Components/Addons/LazyLoader';
import { usePagination } from "../Context/PaginationContext";

const AppSeller = ({ user }) => {
	const { limit, offset, setTotalItems } = usePagination();
	const [logoutModal, setLogoutModal] = useState(false);
	const [books, setBooks] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const logoutModalController = () => {
		setLogoutModal(!logoutModal)
	}

	useEffect(() => {
		setIsLoading(true)
		getBooks()
		setIsLoading(false)
	}, [offset]);

	// get books and set state
	// const getBooks = async () => {
	// 	const response = await getRequest("/seller/ebook/owner-ebook/")
	// 	if(response.statusText === "OK"){
	// 		setBooks(response.data.results)
	// 	} 
	// }

	const getBooks = async () => {
		const response = await getRequest(`/seller/ebook/owner-ebook/?limit=${limit}&offset=${offset}`)
		setBooks(response?.data?.results)
		setTotalItems(response?.data?.count || 0)
	}

	const SelllerStats = lazy(() => import('../Components/SELLER/Statistics/SelllerStats'))

	return (
		<>
			<NavSeller user={user} handleRemoveAuthModal={logoutModalController} />

			{
				logoutModal && <Logout handleRemoveAuthModal={logoutModalController} />
			}
			<div className='seller_pages_with_nav'>
				<Routes>
					<Route path={`/${user?.user?.first_name}`} element={<UserProfile user={user} />} />
					<Route path={`/${user?.user?.first_name}/inbox`} element={<Chat />} />
					<Route path={`/book/create-book`} element={<BookCreate user={user} />} />
					<Route path={`/book/:id`} element={<BookDetails books={books} user={user} />} />
					<Route path={`/ebooks/:id`} element={<BookDetails books={books} user={user} />} />
					<Route path={`/${user?.user?.first_name}/book`} element={<BookCreate user={user} />} />
					<Route path={`/${user?.user?.first_name}/settings`} element={<Settings user={user} />} />
					<Route path={`/${user?.user?.first_name}/profile`} element={<SellerProfile user={user} />} />
					<Route path={`/${user?.user?.first_name}/profile?`} element={<SellerProfileEdit user={user} />} />
					<Route path={`/${user?.user?.first_name}/earnings`} element={<Earning user={user} />} />
					<Route path={`/${user?.user?.first_name}/transactions`} element={<TransactionHistory user={user} />} />


					<Route path='/' element={<Dashboard user={user} books={books} isLoading={isLoading} />} />
					<Route path='/me/:id' element={<StoreProfile user={user} />} />

					<Route 
						path={`/${user?.user?.first_name}/statistics`} 
						element={
							<Suspense 
							fallback={<lazyLoader/>}
						>
							<SelllerStats />
						</Suspense>
					} />

					<Route path='/me/*' element={<Dashboard user={user} books={books} isLoading={isLoading} />} />
					<Route path='/switch-to-buying' element={<InfoSwitchBuying />} />
					<Route path={`/${user?.user?.first_name}/dashboard`} element={<Dashboard user={user} books={books} />} />
					<Route path={`/${user?.user?.first_name}/discount?`} element={<Discount books={books} />} />
					{/* <Route path='/login' element={<Login />} /> */}
					<Route path='/terms-and-conditions' element={<SellerPrivacy />} />


				{/* CONTACT ADMIN AND FAQ  */}
				<Route path='/frequently-asked-question/*' element={<Faq />} />
				<Route path='/help/contact-support' element={ <Contact /> } />
        		<Route path='/help/message-submitted' element={ <RequestSubmitted /> } />
				</Routes>
			</div>

		</>
	)
}

export default AppSeller
