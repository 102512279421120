import React, { useState, useEffect, useRef } from 'react';
import './CountryPhoneInput.css';

const CountryPhoneInput = ({ setCountry, setPhone, phone }) => {
    const [countryString, setCountryString] = useState('');
    const [phoneLocal, setPhoneLocal] = useState(phone ? phone : '');  // Strip out the country code if it exists
    const [countryList, setCountryList] = useState([]);
    const [countrySearchResponse, setCountrySearchResponse] = useState([]);
    const [countryFlag, setCountryFlag] = useState(null);
    const [countryCode, setCountryCode] = useState('');
    const [showCountryDropdown, setShowCountryDropdown] = useState(false);

    const phoneNumberRef = useRef(null);

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        if (phone) {
            // setPhoneLocal(phone.replace(/^\+?\d{1,4}\s?/, ''));  // Remove the country code from the phone number
            detectCountryFromPhone(phone); // Detect the country from the full phone number
        }
    }, [phone, countryList]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (showCountryDropdown && !e.target.closest('.country-wrapper')) {
                setShowCountryDropdown(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [showCountryDropdown]);
    
    useEffect(() => {
        if (phoneNumberRef.current) {
            phoneNumberRef.current.focus();
            // Place le curseur à la fin du texte
            // const length = phoneNumberRef.current.value.length;
            // phoneNumberRef.current.setSelectionRange(length, length);
        }
    }, [countryCode]);

    const fetchCountries = async () => {
        if (countryList.length < 1) {
            const response = await fetch(
                'https://restcountries.com/v3.1/all?fields=name,flags,idd'
            );
            const data = await response.json();
            const filteredData = data.filter(country => country.idd?.root);
            filteredData.sort((a, b) => a.name.common.localeCompare(b.name.common));
            setCountryList(filteredData);
            setCountrySearchResponse(filteredData);
        }
    };

    const searchCountry = (e) => {
        const searchValue = e.target.value.toLowerCase();
        const filteredList = countryList.filter((country) =>
            country.name.common.toLowerCase().startsWith(searchValue)
        );
        setCountrySearchResponse(filteredList);
    };

    const saveCountry = (country) => {
        if (!country) return;
    
        let code = country?.idd?.root || ''; // Récupère la racine de l'IDD
        if (country?.idd?.suffixes?.length === 1) {
            code += country?.idd?.suffixes[0]; // Concatène le suffixe s'il est unique
        }
    
        // Mettre à jour les états
        setCountryFlag(country?.flags?.svg);
        setCountryCode(code);
        setPhoneLocal(code + ' '); // Assure que le champ phoneLocal contient le code complet
        setPhone(code + ' '); // Met à jour le téléphone complet (avec code) dans le parent
        setCountry(country?.name?.common); // Met à jour le pays sélectionné
        setCountryString(country?.name?.common); // Affiche le nom du pays dans l'input
        setShowCountryDropdown(false);
        console.log('country selected : ', country);
        console.log('value of the code computed', code)

        // Focalise directement sur le champ du téléphone
        setTimeout(() => {
            if (phoneNumberRef.current) {
           }      phoneNumberRef.current.focus();
           
        }, 0);
    };
    

    const handlePhoneChange = (e) => {
        const value = e.target.value;

        // If the value starts with the country code, keep the country code and update the local part of the phone number
        if (value.startsWith(countryCode)) {
            setPhoneLocal(value);  // Keep the phone number with country code intact
        } else {
            setPhoneLocal(value);  // Append the country code before the local number
        }

        // Concatenate country code and remove all spaces
        const formattedPhone = (value).replace(/\s+/g, '');  // Remove spaces from the phone number
        setPhone(formattedPhone); // Update phone number in parent with the full number including the code
    };

    const detectCountryFromPhone = (phoneNumber) => {
        const country = countryList.find((country) => {
            const countryCodePattern = country?.idd?.root + (country?.idd?.suffixes[0] || '');
            return phoneNumber.startsWith(countryCodePattern);
        });

        if (country) {
            setCountryFlag(country.flags.svg);
            setCountryCode(country?.idd?.root);
            setCountry(country?.name?.common);
            setCountryString(country?.name?.common);  // Update the country string if a match is found
            setPhoneLocal(phoneNumber);  // Update the phone number without the country code
        }
    };

    return (
        <div className="country-phone-input">
            <div className="country-wrapper">
                <div className="input-field country-list-auth">
                    <input
                        type="text"
                        onFocus={() => setShowCountryDropdown(true)}
                        onChange={(e) => {
                            setCountryString(e.target.value);
                            searchCountry(e);
                        }}
                        value={countryString}  // Display selected country in the input
                        placeholder="Select a country"
                    />
                </div>
                {showCountryDropdown && (
                    <div className="country-list-suggestions">
                        {countrySearchResponse.map((country, index) => (
                            <div
                                key={index}
                                className="country-list-item"
                                onMouseDown={() => saveCountry(country)}
                            >
                                <span className="icon">
                                    <img
                                        src={country.flags.svg}
                                        alt={country.name.common}
                                    />
                                </span>
                                <span className="countryname">
                                    {country.name.common}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="input-field phone-input">
                <input
                    ref={phoneNumberRef}
                    type="text"
                    value={phoneLocal}
                    onChange={handlePhoneChange}
                    placeholder="Enter your phone number"
                />
                {countryFlag && (
                    <span className="preview-country-icon">
                        <img src={countryFlag} alt={countryCode} />
                    </span>
                )}
            </div>
        </div>
    );
};

export default CountryPhoneInput;
