import React from 'react';
import { useTranslation } from 'react-i18next';
import {motion} from "framer-motion";

const BalanceInsufficientModal = ({ setVisible }) => {
    const { t } = useTranslation();
    return (
            <div className="panel">
                <motion.div
                    className="instructions"
                    initial={{scale: 0.9, opacity: 0}}
                    animate={{scale: 1, opacity: 1}}
                    exit={{scale: 0.9, opacity: 0}}
                >
        <span className="close" onClick={() => setVisible(false)}>
          &times;
        </span>
                    <h2>
                    <span className="balance">
                        <span className="account_details_title">
                            {t('insufficient_balance_modal.title')}<br/>{t('insufficient_balance_modal.minimum')} <span>{t('insufficient_balance_modal.amount')} </span>
                            {t('insufficient_balance_modal.proceed')}
                        </span>
                    </span>
                    </h2>
                </motion.div>
            </div>
    );
};

export default BalanceInsufficientModal;