import React, {useState, useRef, useEffect, useMemo} from 'react';
import useLocalStorage from '../../../lib/UseLocalStorage';
import { postRequest } from "../../../../Resquest";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import {toast} from "sonner";

const VerifyOtp = ({ setOtpVisible, Id, user }) => {
    const [otpCode, setOtpCode] = useState('');
    const inputRefs = useMemo(() => Array.from({ length: 6 }, () => React.createRef()), []); // Predefined array of refs
    const [countdown, setCountdown] = useLocalStorage('otpCountdown', 600);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSubmit = async () => {
        const data = {
            withdrawal_id: Id,
            otp: otpCode,
        };
        const res = await postRequest('/seller/withdrawals/verify-otp/', data);
        if (res?.status === 200) {
            toast.success("Withdrawal was successfull", {
                position: "bottom-right",
            });
            navigate(`/${user?.user?.first_name}/transactions`);
        } else {
            alert(t('withdrawalsPage.invalidOtpCode'));
        }
    };

    useEffect(() => {
        if (otpCode.length === 6) { // Changed to 6
            handleSubmit();
        }
    }, [otpCode]);

    useEffect(() => {
        const storedTimestamp = localStorage.getItem('otpCountdownTimestamp');
        const currentTime = Math.floor(Date.now() / 1000);

        if (storedTimestamp) {
            const elapsedTime = currentTime - parseInt(storedTimestamp, 10);
            const remainingTime = Math.max(0, countdown - elapsedTime);
            setCountdown(remainingTime);
        }
        localStorage.setItem('otpCountdownTimestamp', currentTime.toString());
    }, [countdown]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => Math.max(0, prevCountdown - 1));
        }, 1000);
        return () => clearInterval(timer);
    }, [setCountdown]);

    const handleKeyDown = (e, index) => {
        const pressedKey = e.key;
        if (/^[0-9]$/.test(pressedKey)) {
            setOtpCode((prev) => {
                const updated = prev.slice(0, index) + pressedKey + prev.slice(index + 1);
                return updated.length <= 6 ? updated : prev; // Adjusted to 6
            });
            if (index < 5) {
                inputRefs[index + 1].current.focus();
            }
        } else if (pressedKey === 'Backspace' && index > 0) {
            setOtpCode((prev) => prev.slice(0, index - 1) + prev.slice(index));
            inputRefs[index - 1].current.focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedValue = e.clipboardData.getData('text').slice(0, 6); // Adjusted to 6
        setOtpCode(pastedValue);
    };

    return (
        <div className='panel'>
            <motion.div className='verify_number_container'
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
            >
                <p className='verify_number_title'>{t('transactions.verifyOtpTitle')}
                    <span className="close" onClick={() => setOtpVisible(false)}>
                        &times;
                    </span>
                </p>
                <div className='verification_number_fields'>
                    {Array.from({ length: 6 }, (_, index) => (
                        <div key={index} className='verification_letter'>
                            <input
                                type='text'
                                value={otpCode[index] || ''}
                                className='verification_input_number'
                                ref={inputRefs[index]}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onPaste={handlePaste}
                                maxLength={1} // Limit input to 1 character
                            />
                        </div>
                    ))}
                </div>
                <div className='verify_number_btn' onClick={handleSubmit}>
                    {t('transactions.verifyOtpButton')}
                </div>
                <div className='verification_number_description'>
                    <p className='code_not_sent'>
                        {t('transactions.noOtpCode')} <span className='link'>{t('transactions.restartTransaction')}</span>
                    </p>
                    <p className='ver_code_timer'>
                        {t('transactions.otpExpiresIn')} {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')} minutes.
                    </p>
                </div>
            </motion.div>
        </div>
    );
};

export default VerifyOtp;
