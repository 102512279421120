import React, { useContext, useEffect, useState } from 'react';
import './Cart.css';
import { BsShieldCheck } from 'react-icons/bs';
import CartItem from './CartItem';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../../Context/CartContext';
import useLocalStorage from '../lib/UseLocalStorage';
import { useTranslation } from 'react-i18next';
import { postRequest } from '../../Resquest';
import { WishListContext } from '../../Context/WishListContext';
import Popup from '../Addons/Popup';

const Cart = () => {
	const [cart, setCart] = useContext(CartContext);
	const [user, setUser] = useLocalStorage('user');
	const [redirectUrl, setRedirectUrl] = useLocalStorage('redirectUrl');
	const [total, setTotal] = useState(0);
	const navigate = useNavigate();
	const [wishList, setWishList] = useContext(WishListContext);
	const [wishListPopup, setWishListPopup] = useState(false);

	const showWishListPopup = () => {
		setWishListPopup(true);
		setTimeout(() => setWishListPopup(false), 3000);
	};

	const removeProduct = (id) => {
		setCart(cart.filter(item => item.slug !== id));
	};

	useEffect(() => {
		getTotal();
	}, [cart]);

	const wishListChanged = (product) => {
		if (!user) {
			// Handle login alert or redirect to login page
		} else {
			if (wishList.some(item => item.slug === product.slug)) {
				setWishList(wishList.filter(item => item.slug !== product.slug));
			} else {
				setWishList([...wishList, product]);
			}
		}
	};

	const getTotal = () => {
		let total = 0;
		cart.map(item => total += Number(item.price));
		setTotal(total);
	};

	const checkoutUser = () => {
		if (!user) {
			setRedirectUrl('/checkout');
			navigate('/login');
		} else {
			navigate('/checkout');
		}
	};

	const submitCart = async () => {
		const data = {
			"products": []
		};
		const res = await postRequest('/cart-item/', data);
	};

	const { t } = useTranslation();
	return (
		<div className='container'>
			<div className='cart'>
				<div className='cart_list_section'>
					<div className='cartSection'>
						<div className='title'>{t("cart_page.title")} ({cart.length})</div>
						<div className='desc'>
							<span className='icon'><input type='radio' /></span>
							<span>{t("cart_page.select")}</span>
						</div>
					</div>
					<div className='cartItemsSection'>
						<div className='desc lineCart'>
							<span className='icon'><input type='radio' /></span>
							<span className='title'>{t("cart_page.ebook")}</span>
						</div>
						{cart.map(product => (
							<CartItem
								key={product.slug}
								product={product}
								handleRemove={removeProduct}
								wishListChanged={(product) => {
									wishListChanged(product);
									showWishListPopup();
								}}
								animateWishListAdded={showWishListPopup}
							/>
						))}
					</div>
				</div>
				<div className='cart_summary'>
					<div className='summary'>
						<div className='title'>{t("cart_page.summary")}</div>
						<div className='summary_det'>
							<p>{t("cart_page.sub_total")}</p>
							<p>XAF {total}</p>
						</div>
						<div className='summary_det'>
							<p>{t("cart_page.total")}</p>
							<p>XAF {total}</p>
						</div>
						<div onClick={checkoutUser} draggable className='checkout_button'>{t("cart_page.checkout")} ({cart.length})</div>
					</div>
					<div className='summary payment_methods'>
						<div className='title'>{t("cart.pay")}</div>
						<div className='payIcons'>
							<span className='icon'></span>
							<span className='icon'></span>
							<span className='icon'></span>
						</div>
						<div className='title'>{t("cart.bp")}</div>
						<p>
							<span><BsShieldCheck /> </span>
							<span className='protect'>{t("cart.bp_desc")}</span>
						</p>
					</div>
				</div>
			</div>
			<Popup handleRemoveModal={showWishListPopup} showPopup={wishListPopup} text={t("modals.add_to_wishlist.text")} />
		</div>
	);
};

export default Cart;