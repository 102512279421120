import React from 'react';
import {motion} from "framer-motion";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CreatePaymentDetailModal = ({ user, setVisible }) => {

    const { t } = useTranslation();
    return (
        <div className="panel">
            <motion.div
                className="instructions"
                initial={{scale: 0.9, opacity: 0}}
                animate={{scale: 1, opacity: 1}}
                exit={{scale: 0.9, opacity: 0}}
            >
        <span className="close" onClick={() => setVisible(false)}>
          &times;
        </span>
                <h2>
                    <span className="balance">
                        <span className="account_details_title">
                            {t('create_payment_details_modal.instruction')} <Link to={`/${user?.user?.first_name}/profile?edit=true`} >{t('create_payment_details_modal.do_here')}.</Link>
                        </span>
                    </span>
                </h2>
            </motion.div>
        </div>
    );
};

export default CreatePaymentDetailModal;