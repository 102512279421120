import React, { useState, useEffect} from 'react';
import DataTable from "react-data-table-component";
import { useTranslation } from 'react-i18next';

import {getRequest} from "../../../../Resquest";

const HistoryDataTable = () => {
    const { t } = useTranslation();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ historyData, setHistoryData ] = useState([]);


    

    const getTransactionsHistory = async () => {
        setIsLoading(true);
        try {
            const response = await getRequest("/seller/withdrawals/history/");
            if (response.status === 200) {
                setHistoryData(response?.data?.results);
                setIsLoading(false);
            }
        } catch (e) {
            console.error("error while getting history data", e);
        } finally {
            setIsLoading(false);
        }

    };

    useEffect(() => {
        getTransactionsHistory();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
    };
    return (
        <>
            <h2 className="transaction_title">{t('transactions.history.title')}</h2>
            <DataTable
                columns={[
                    {
                        name: <div className="row_item_stat row_item_header_stat">{t('transactions.history.id')}</div>,
                        selector: (row) => <div className="row_item_stat">{row.id}</div>,
                        sortable: true,
                        center: true
                    },
                    {
                        name: <div className="row_item_stat row_item_header_stat">{t('transactions.history.processedAt')}</div>,
                        selector: (row) => (
                            <div className="row_item_stat">
                                {row.processed_at ? formatDate(row.processed_at) : "N/A"}
                            </div>
                        ),
                        sortable: true,
                        center: true
                    },
                    {
                        name: <div className="row_item_stat row_item_header_stat">{t('transactions.history.requestedAt')}</div>,
                        selector: (row) => (
                            <div className="row_item_stat">
                                {row.requested_at ? formatDate(row.requested_at) : "N/A"}
                            </div>
                        ),
                        sortable: true,
                        center: true
                    },
                    {
                        name: <div className="row_item_stat row_item_header_stat">{t('transactions.history.status')}</div>,
                        selector: (row) => (
                            <div
                                className={`row_item_stat status ${row.status.toLowerCase()}`}
                            >
                                {row.status}
                            </div>
                        ),
                        sortable: true,
                        center: true
                    },
                    {
                        name: <div className="row_item_stat row_item_header_stat">{t('transactions.history.amount')} (XAF)</div>,
                        selector: (row) => <div className="row_item_stat">{row.amount}</div>,
                        sortable: true,
                        center: true
                    },
                    {
                        name: <div className="row_item_stat row_item_header_stat">{t('transactions.history.verified')}</div>,
                        selector: (row) => (
                            <div className="row_item_stat">{row.is_verified ? "Yes" : "No"}</div>
                        ),
                        sortable: true,
                        center: true
                    },
                ]}
                data={historyData}
                pagination
                striped
                selectableRowsHighlight
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15]}
                highlightOnHover
                fixedHeader
                fixedHeaderScrollHeight="400px"
                responsive
            />

        </>
    );
};

export default HistoryDataTable;