import React, { useState } from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { CiMenuKebab } from "react-icons/ci";
import { VscChromeClose } from 'react-icons/vsc';
import { Link, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { deleteRequest } from '../../../../Resquest';
import { useTranslation } from 'react-i18next';
import {toast} from "sonner";

const variants = {
	hidden: {
		y: "10vh",
		opacity: 0,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		}
	},
	visible: {
		y: "0vh",
		opacity: 1,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		}
	},
	exit: {
		y: "10vh",
		opacity: 0,

	}
}

const SellerBook = ({ book, user }) => {
	const [showMenu, setShowMenu] = useState(false);
	const [copied, setCopied] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false)

	const navigate = useNavigate()

	const copyLink = () => {
		setShowMenu(false)
		const link = window.location.origin + '/ebooks/' + book?.slug
		navigator.clipboard.writeText(link)
		setCopied(true)
		setShowMenu(false)
		setTimeout(() => {
			setCopied(false)
		}, 3000);
	}
	const editBook = () => {
		navigate(`/${user?.user?.first_name}/book?edit=true`,
			{state: {...book}}
		)
	}

	const deleteModalState = () => {
		setDeleteModal(true)
		setShowMenu(false)
		setTimeout(() => {
			setDeleteModal(false)
		}, 7000);
	}

	const deleteBook = async () => {
		setDeleteModal(false);
		const response = await deleteRequest(`/seller/ebook/owner-ebook/${book.slug}/`)
		if(response.status === 204){
			toast.success(t('book.delete'), {
				position: 'bottom-right'
			})
			window.location.reload()
		}
	}
	
	const removeCopyModal = () => {
		setCopied(false)
	}

	const { t } = useTranslation()
	return (
		<>
			<div onClick={() => setShowMenu(false)} className={`seller_shadow ${showMenu ? 'showSellerShadow' : ''}`} />
			<div className='book'>
				<div className='book_head'>
					<div><Link to={`/book/${book.slug}`}><h2 className='book_title'>{book?.name}</h2></Link></div>
					<div className='book_menu'>
						<div onClick={() => setShowMenu(!showMenu)} className='book_menu_dropdownbtn'><CiMenuKebab /></div>
						{
							showMenu && (
								<div className='book_menu_dropdown'>
									<div onClick={() => editBook()} className='book_menu_item'>{ t("seller.edit") }</div>
									<div onClick={copyLink} className='book_menu_item'>{ t("seller.copy") }</div>
									<div onClick={() => deleteModalState()} className='book_menu_item delete_book'>{ t("modals.delete_book.btn") }</div>
								</div>
							)
						}
					</div>
				</div>
				<div className='description'>
					<p>{book?.description.length > 100 ? `${book?.description.substring(0, 100)}...` : book?.description}</p>
				</div>
				<div className='seller_book_tags'>
					{book?.in_review_mode ?
						<div draggable={true} className='book_tag pending'>{ t("seller.state_pend") }</div> :
						<div draggable={true} className='book_tag active'>{ t("seller.state_active") }</div>
					}
					{/* <div draggable={true} className='book_tag reject'>Rejected</div> */}

				</div>
			</div>
			<AnimatePresence
				initial={false}
				mode='wait'
			>
				{
					copied && (
						<CopyAlert removeCopyModal={removeCopyModal} />
					)
				}
				{
					deleteModal && (
						<div className='book_modalWrapper'>
							<motion.div
								className='book_notify_modal book_deleteModal'
								variants={variants}
								initial="hidden"
								animate="visible"
								exit="exit"
							>
								<div>
									<p className='modal_notify_text'>{ t("modals.delete_book.text") }</p>
									<button className="discount-btn-delete" onClick={deleteBook}>{ t("modals.delete_book.btn") }</button>
								</div>
								<span onClick={() => setDeleteModal(false)}><VscChromeClose /></span>
							</motion.div>
						</div>
					)
				}
			</AnimatePresence>
		</>
	)
}

export const CopyAlert = ({ removeCopyModal }) => {
	const { t } = useTranslation()
	return (
		<div className='book_modalWrapper'>
			<motion.div
				className='book_notify_modal'
				variants={variants}
				initial="hidden"
				animate="visible"
				exit="exit"
			>
				<span className='checkmark_book'><BsFillCheckCircleFill /></span>
				<p className='modal_notify_text'>{ t("alerts.copied") }</p>
				<span onClick={removeCopyModal}><VscChromeClose /></span>
			</motion.div>
		</div>
	)
}
const ListBooks = ({ books, user }) => {
	return (
		<div className='seller_books_grid'>

			{
				books?.map((book) => (
					<SellerBook key={book.slug} book={book} user={user} />
				))
			}

		</div>
	)
}

export default ListBooks
