import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const CartItem = ({ product, handleRemove, wishListChanged, animateWishListAdded }) => {
    const location = useLocation();
    const { t } = useTranslation();

    // State to track if we are on the wishlist route
    const [isWishlistRoute, setIsWishlistRoute] = useState(false);

    // Update the state when the route changes
    useEffect(() => {
        const isWishlist = location.pathname === '/wishList';
        console.log("the wishlist route", isWishlist);  // Debugging
        setIsWishlistRoute(isWishlist);
    }, [location.pathname]);

    const removeItem = () => {
        if (isWishlistRoute) {
            wishListChanged(product);
        } else {
            handleRemove(product.slug);
        }
    };

    return (
        <div className="cartItemWrapper">
            <div className="cartFlexItem cartImageWrapper">
                <img src={product.cover} alt={product.name} />
            </div>
            <div className="cartFlexItem cartDescription">
                <h3 className="cartItemName">{product.name}</h3>
                <div className="rating remove">
                    {/* Rating logic */}
                </div>
                <div className="cartMobile_price">{product.price}</div>
            </div>
            <div className="cartFlexItem cartControl">
                <div className="cartPrice">{product.price}</div>
                <div className="cartBtnControl">
                    {/* Only show "Remove" or "Remove from Wishlist" depending on the route */}
                    {isWishlistRoute ? (
                        <span onClick={removeItem} className="cartBtn">
                            {t("cart_page.remove_from_wishlist")}
                        </span>
                    ) : (
                        <span onClick={removeItem} className="cartBtn">
                            {t("cart_page.remove_btn")}
                        </span>
                    )}

                    {/* Only show the "Add to Wishlist" button if we're NOT on the WishList route */}
                    {!isWishlistRoute && (
                        <span
                            onClick={() => {
                                wishListChanged(product);
                                animateWishListAdded();
                            }}
                            className="cartBtn cartSaveToLater add"
                        >
                            {t("cart_page.add_btn")}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CartItem;