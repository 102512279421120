import React, { useEffect, useState } from 'react'
import './SellerOnboarding.css'
import Person from './person.avif'
import Man from './man.jpg'
import Mother from './mother.jpg'
import Learning from './start_selling.mp4'
import Girl from './girl.avif'
import LearningImage from '../../Assets/img/bg-teacher3.jpg';
import { useNavigate } from 'react-router-dom'
import useLocalStorage from '../lib/UseLocalStorage'
import { simplePatchRequest } from '../../Resquest'
import { useTranslation } from 'react-i18next'
import { tabTitle } from '../..'
import {ReactComponent as Loader} from '../../Assets/Loaders/loader.svg'

const SellerOnboarding = () => {
	const [user, setUser] = useLocalStorage('user')
    const navigate = useNavigate()
    const [isloading, setIsloading] = useState(false)

    useEffect(() => {
		if(user?.user?.is_seller){
			navigate('/dashboard')
		}
	}, [navigate, user?.user?.is_seller])
    
    tabTitle('Intégration du vendeur HooYia')

    const requestMakeSeller = async() => {
        setIsloading(true)
        const res = await becomeSeller()
        if(res.status === 200) {
            navigate('/seller-onboarding/seller-details')
        }
        setIsloading(false)
    }

    const becomeSeller = async() => {
        const res = await simplePatchRequest("https://hooyia.herokuapp.com/api/v1/auth/profile/", {"is_seller_account":true})
        return res
    }

    const { t } = useTranslation()
  return (
    <div className='seller_onboarding'>
        <div className='onboard_landing'>
            <div className='bg_vid'>
                <img src={LearningImage} alt="Learning visual" id="myVideo"/>
            </div>
            <div className='container'>
                <h2 className='onboard_title'>{ t("seller_onboarding.title") }</h2>
                <p className='onboard_desc'>{ t("seller_onboarding.desc") } 🚀📖</p>
                <div className="d_flex">
                    {
                        isloading
                            ?
                            <button className='sellerBtn' style={{ cursor: 'progress'}}><span className='loadingButton'><Loader /></span></button>
                            :
                            <button onClick={requestMakeSeller} className='sellerBtn'>{ t("seller_onboarding.btn") }</button>
                    }
                    <a href="/terms-and-conditions">{ t("randoms.seller_policy") }</a>
                </div>
            </div>
        </div>

        <section className='join_community'>
            <div className='container'>
                <h2 className="onboarding_subtitle">{ t("seller_onboarding.grid.title") }</h2>
            </div>
        </section>

        <section className='how_it_works'>
            <div className='container'>
                <h2>{ t("seller_onboarding.grid.how_it_works") }</h2>
                <div className='how_it_works_grid'>
                    <div className='sec'>
                        <h3>1. { t("seller_onboarding.grid.one.title") }</h3>
                        <p>{ t("seller_onboarding.grid.one.content") }</p>
                    </div>
                    <div className='sec'>
                        <h3>2. { t("seller_onboarding.grid.two.title") }</h3>
                        <p>{ t("seller_onboarding.grid.two.content") }</p>
                    </div>
                    <div className='sec'>
                        <h3>3. { t("seller_onboarding.grid.three.title") }</h3>
                        <p>{ t("seller_onboarding.grid.three.content") }</p>
                    </div>
                </div>
            </div>
        </section>

        <section className='q_and_a'>
            <div className='container'>
                <h2>Q&A</h2>
                <div className='q_a_a_wrapper'>
                    <div className='dropdown'>
                        <div className='question'>{ t("seller_onboarding.faqs.one.question") }</div>
                        <div className='answer'>{ t("seller_onboarding.faqs.one.answer") }</div>
                    </div>
                    <div className='dropdown'>
                        <div className='question'>{ t("seller_onboarding.faqs.two.question") }</div>
                        <div className='answer'>{ t("seller_onboarding.faqs.two.answer") }</div>
                    </div>
                    <div className='dropdown'>
                        <div className='question'>{ t("seller_onboarding.faqs.three.question") }</div>
                        <div className='answer'>{ t("seller_onboarding.faqs.three.answer") }</div>
                    </div>
                    <div className='dropdown'>
                        <div className='question'>{ t("seller_onboarding.faqs.four.question") }</div>
                        <div className='answer'>{ t("seller_onboarding.faqs.four.answer") }</div>
                    </div>
                    <div className='dropdown'>
                        <div className='question'>{ t("seller_onboarding.faqs.five.question") }</div>
                        <div className='answer'>{ t("seller_onboarding.faqs.five.answer") }</div>
                    </div>
                </div>
            </div>
        </section>

        <section className='onboading_stories'>
            <div className='container'>
                <h2>{ t("seller_onboarding.testimonials.title") }</h2>
                <div className='onboarding_slider'>

                    <div className='grid_stories'>
                        <div className='flex_stories'>
                            <div className='img_user'>
                                <img src={Person} alt='christopher' />
                            </div>
                            <div className='quote'>
                                <div className='quote_arrow' />
                                <p className='quote_text'>"{t("seller_onboarding.testimonials.kimbi.quote")}" {t("seller_onboarding.testimonials.kimbi.role")}</p>
                                <p className='quote_name'>{t("seller_onboarding.testimonials.kimbi.name")}</p>
                            </div>
                        </div>
                        <div className='flex_stories'>
                            <div className='img_user'>
                                <img src={Mother} alt='christopher' />
                            </div>
                            <div className='quote'>
                                <div className='quote_arrow' />
                                <p className='quote_text'>"{t("seller_onboarding.testimonials.sonia.quote")}",{t("seller_onboarding.testimonials.sonia.role")}</p>
                                <p className='quote_name'>{t("seller_onboarding.testimonials.sonia.name")}</p>
                            </div>
                        </div>
                        <div className='flex_stories'>
                            <div className='quote'>
                                <p className='quote_text'>"{t("seller_onboarding.testimonials.stephania.quote")}" {t("seller_onboarding.testimonials.stephania.role")}</p>
                                <p className='quote_name'>{t("seller_onboarding.testimonials.stephania.name")}</p>
                                <div className='quote_right' />
                            </div>
                            <div className='img_user'>
                                <img src={Girl} alt='christopher' />
                            </div>
                        </div>
                        <div className='flex_stories'>
                            <div className='quote'>
                                <p className='quote_text'>"{t("seller_onboarding.testimonials.herman.quote")}" {t("seller_onboarding.testimonials.herman.role")}</p>
                                <p className='quote_name'>{t("seller_onboarding.testimonials.herman.name")}</p>
                                <div className='quote_right' />
                            </div>
                            <div className='img_user'>
                                <img src={Man} alt='christopher' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='last'>
            <div className='container'>
                <div className='flex'>
                    <h3>{t("seller_onboarding.start_selling.text")}</h3>
                    <button className='start_selling' onClick={requestMakeSeller}>{t("seller_onboarding.start_selling.btn")}</button>
                </div>
            </div>
        </section>
    </div>
  )
}

export default SellerOnboarding