import React, { useContext } from 'react'
import { VscChromeClose } from 'react-icons/vsc'
import { UserContext } from '../../Context/AuthContext';
import { useNavigate } from 'react-router';
import { async } from 'q';
import { postRequest } from '../../Resquest';
import { useTranslation } from 'react-i18next';

const Logout = ({ handleRemoveAuthModal }) => {
    const [user, setUser] = useContext(UserContext);
    const navigate = useNavigate()

    const logoutUser = () => {
        requestUserLogout()
            .then((response) => {
                localStorage.removeItem('user');
                localStorage.removeItem('redirectUrl');
            })
            .then(() => {
                // Redirect the user to the home page after logging out
                navigate('/', { state: { "logout": true } });
            })

    };


    const requestUserLogout = async () => {
        // const token = user?.access
        const response = await postRequest('/auth/logout/', { "revoke_token": true })
        return response
    }
    const { t } = useTranslation()
    return (
        <div className='user_auth_popup'>
            <div onClick={handleRemoveAuthModal} className='shadow_user' />
            <div className='auth_u'>
                <div onClick={handleRemoveAuthModal} className='closeModal'><VscChromeClose /></div>
                <div className='loginModal_head'>
                    <p className=''>{ t("alerts.logout") } </p>
                </div>
                <div className='logout_modalBtn_wrapper'>
                    <div onClick={handleRemoveAuthModal} className='cancel_logoutBtn'>{ t("alerts.cancel") } </div>
                    <div onClick={logoutUser} className='modal_logoutBtn'>{ t("alerts.logout_btn") } </div>
                </div>
            </div>
        </div>
    )
}

export default Logout
