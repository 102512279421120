import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import "./SellerProfile.css";
import DefaultProfilePic from "../../../../Assets/img/personPlaceholder.jpg";
import {useNavigate} from "react-router-dom";
import {IoPencilOutline} from "react-icons/io5";
import {VscPerson} from "react-icons/vsc";
import {AiOutlineBank} from "react-icons/ai";
import {SlBadge} from "react-icons/sl";
import {TfiBook} from "react-icons/tfi";
import { patchRequest } from "../../../../Resquest";
import {useTranslation} from "react-i18next";
import {
    AWSbaseURL,
    handleHTTPResponse,
    postRequest,
} from "../../../../Resquest";
import {ReactComponent as ThreeDots} from "../../../../Assets/Loaders/ThreeDots.svg";
import VerifySeller from "../Components/VerifyUser";
import MyBooks from "../../../Products/MyBooks";
import {useStats} from "react-instantsearch";
import useLocalStorage from "../../../lib/UseLocalStorage";
import {
    getAllBanks,
    getCountry,
    getRequest,
    deleteRequest,
} from "../../../../Resquest";
import SellerBook from "./SellerBook";
import NoBooks from "../Components/NoBooks";
import LoadingPage from "../Components/LoadingPage";
import axios from "axios";
import {UserContext} from "../../../../Context/AuthContext";
import {BsChevronDown} from "react-icons/bs";
import {isEditable} from "@testing-library/user-event/dist/utils";
import {toast} from "sonner";
import {PhoneValidation} from "../../../Addons/validations";
import {usePagination} from "../../../../Context/PaginationContext";
import Pagination from "../../../Addons/Pagination/pagination";
import useCountry from "../../../hooks/useCountry";
import CountryPhoneInput from "./CountryPhoneInput";
import {HiInformationCircle} from "react-icons/hi";
import {motion, AnimatePresence} from "framer-motion";
import DataTable from "react-data-table-component";
import {FaCheck, FaTimes, FaPenSquare} from "react-icons/fa";
import { FaTrash } from "react-icons/fa";

const SellerProfile = ({user}) => {
    // const [ updateUser, setUpdateUser ] = useLocalStorage('user');
    const [profileTab, setProfileTab] = useState("personal_info");
    const [User, setUser] = useContext(UserContext);
    const [isNameEditable, setIsNameEditable] = useState(false);
    const [profilePic, setProfilePic] = useState(null);
    const [fname, setFName] = useState(user?.user?.first_name);
    const [lname, setLName] = useState(user?.user?.last_name);
    const {t} = useTranslation();

    const data = {
        // "user": User?.user,
        first_name: fname,
        last_name: lname,
    };
    const saveEditName = async () => {
        if (!user?.user?.profile?.pk) {
            console.error("Profile ID is missing.");
            return;
        }

        try {
            const resp = await patchRequest(
                `https://hooyia.herokuapp.com/api/v1/auth/seller-profile/${user.user.profile.pk}/`,
                data
            );

            if (resp.status === 200) {
                setUser((prevState) => ({
                    ...prevState,
                    user: resp.data?.user || prevState.user,
                }));
                toast.success(t("response_handler.success"), {
                    position: "bottom-left",
                    duration: 4000,
                });
            }

            setIsNameEditable(false);
        } catch (error) {
            toast.error(t("response_handler.uncaught_error"));
            console.error("Error during update:", error);
            setIsNameEditable(false);
        }
    };

    useEffect(() => {
        setFName(User?.user?.first_name);
        setLName(User?.user?.last_name);
    }, []);
    /**
     * Receives input field param and saves
     * @param { Object } event param from input field
     */
    const handleFileInput = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
            if (
                file.name.includes(".png") ||
                file.name.includes(".jpeg") ||
                file.name.includes(".jpg")
            ) {
                setProfilePic(file);
            }
        }
    };

    const save_profile_pic = async () => {
        const formData = new FormData();
        formData.append("pp", profilePic, profilePic?.name);
        const resp = await axios.patch(
            `https://hooyia.herokuapp.com/api/v1/auth/seller-profile/${user?.user?.profile?.pk}/`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${user?.access}`,
                },
            }
        );
        updatePPField(resp?.data?.pp);
    };

    const updatePPField = (profile) => {
        setUser((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                profile: {
                    ...prevState.user.profile,
                    pp: profile,
                },
            },
        }));
        setProfilePic(null);
    };

    const handleChangeEdit = () => {
        setIsNameEditable(!isNameEditable);
    };

    const handleTabChange = (tab) => {
        setProfileTab(tab);
    };

    return (
        <div className="seller_profile">
            <div className="container flex">
                <div className="seller_profile_right">
                    <div className="image_and_btn_section">
                        <div className="profilePic">
                            {profilePic ? (
                                <img
                                    src={URL.createObjectURL(profilePic)}
                                    alt="profile_pic_import"
                                />
                            ) : user?.user?.profile?.pp ? (
                                <img
                                    src={AWSbaseURL + user?.user?.profile?.pp}
                                    alt="profile picture"
                                />
                            ) : (
                                <img
                                    src={DefaultProfilePic}
                                    alt="profile picture profile_pic_import"
                                />
                            )}

                            <input
                                className="seller_profile_pic_update"
                                type="file"
                                accept="image/jpeg, image/png, image/jpg"
                                onChange={(e) => handleFileInput(e)}
                            />
                        </div>
                        <span onClick={handleChangeEdit} className="edit_profile edit_icon">
              <IoPencilOutline/>
            </span>
                    </div>
                    {profilePic && (
                        <span onClick={save_profile_pic} className="save_profile_update">
              {t("dashboard_edit.payment_details.profile_edit")}
            </span>
                    )}
                    <div className="profile_text">
                        {isNameEditable ? (
                            <div className=" name_editing_form">
                                <input
                                    type="text"
                                    name="first_name"
                                    className="name_editing_form_item"
                                    value={fname}
                                    onChange={(e) => setFName(e.target.value)}
                                    placeholder="enter your first name"
                                />
                                <input
                                    type="text"
                                    name="last_name"
                                    className="name_editing_form_item"
                                    value={lname}
                                    onChange={(e) => setLName(e.target.value)}
                                    placeholder="enter your last name"
                                />
                                <button
                                    type="submit"
                                    onClick={() => saveEditName()}
                                    className="save_btn"
                                >
                                    Save
                                </button>
                            </div>
                        ) : (
                            <div>
                                <div className="title name_and_edit_field">
                                    {user?.user?.first_name} {user?.user?.last_name}
                                </div>
                                <p className="email_value"> {user?.user?.email}</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="seller_profile_main">
                    <div className="seller_profile_head">
                        <div
                            onClick={() => setProfileTab("personal_info")}
                            className={`seller_profile_head_one ${
                                profileTab === "personal_info" ? "active" : ""
                            }`}
                        >
              <span className="seller_dashboard_mobile">
                <VscPerson className="icon_section_title_edit icon_person"/>{" "}
              </span>
                            <span className="mobile_seller_dashboard">Me</span>
                            <span className="desktop">{t("dashboard_edit.personal")}</span>
                        </div>
                        <div
                            onClick={() => setProfileTab("payment_details")}
                            className={`seller_profile_head_one ${
                                profileTab === "payment_details" ? "active" : ""
                            }`}
                        >
              <span className="seller_dashboard_mobile">
                <AiOutlineBank className="icon_section_title_edit icon_bank"/>{" "}
              </span>
                            <span className="mobile_seller_dashboard">Pay</span>
                            <span className="desktop">{t("dashboard_edit.payment")}</span>
                        </div>
                        {/* <div onClick={() => setProfileTab('achievements')} className={`seller_profile_head_one ${profileTab === 'achievements' ? 'active' : ''}`}>
							<span className='seller_dashboard_mobile'><SlBadge /> </span>
							<span className='mobile_seller_dashboard'>Ach..</span>
							<span className='desktop'>{ t("dashboard_edit.achievement") }</span>
						</div> */}
                        <div
                            onClick={() => setProfileTab("my_books")}
                            className={`seller_profile_head_one ${
                                profileTab === "my_books" ? "active" : ""
                            }`}
                        >
              <span className="seller_dashboard_mobile">
                <TfiBook className="icon_section_title_edit iconBook"/>{" "}
              </span>
                            <span className="mobile_seller_dashboard">
                {t("dashboard_edit.book")}
              </span>
                            <span className="desktop">{t("dashboard_edit.my_books")}</span>
                        </div>
                    </div>
                    {profileTab === "personal_info" ? (
                        <PersonalInfo/>
                    ) : profileTab === "payment_details" ? (
                        <PaymentDetails onRedirect={handleTabChange} />
                    ) : profileTab === "achievements" ? (
                        <Achievements/>
                    ) : profileTab === "my_books" ? (
                        <MySellingBooks/>
                    ) : (
                        <PersonalInfo/>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SellerProfile;

const PersonalInfo = () => {
    const [user, setuser] = useLocalStorage("user");
    const [editing, setEditing] = useState(false);
    const [country, setCountry] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [countryListCopy, setCountryListCopy] = useState([]);
    const [countryDropdownState, setCountryDropdownState] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [city, setCity] = useState("");
    const [address_one, setAddress_one] = useState("");
    const [address_two, setAddress_two] = useState("CCAST Street");
    const [phone_one, setPhone_one] = useState("+237 678 234 567");
    const [phone_two, setPhone_two] = useState("+237 678 234 567");
    const [bio, setBio] = useState("Tell us about yourself...");
    const [bankList, setBankList] = useState([]);
    const [validatedPhone_one, setValidatedPhone_one] = useState(true);
    const [validatedPhone_two, setValidatedPhone_two] = useState(true);
    const [isloading, setIsloading] = useState(false);

    const data = {
        user: user?.user,
        country: selectedCountry.pk,
        city,
        address_line_one: address_one,
        address_line_two: address_two,
        main_phone_number: phone_one,
        alt_phone_number: phone_two,
        bio,
    };

    const saveEdit = async () => {
        setIsloading(true);
        try {
            const resp = await patchRequest(
                `https://hooyia.herokuapp.com/api/v1/auth/seller-profile/${user?.user?.profile?.pk}/`,
                data
            );
            if (resp.status === 200) {
                setuser({...user, user: resp.data?.user});
                toast.success(t("response_handler.success"), {
                    position: "bottom-center",
                });
            } else {
                handleHTTPResponse(resp);
            }
        } catch (error) {
            console.error("Error updating profile:", error);
            toast.error(t("response_handler.error"), {position: "bottom-center"});
        } finally {
            setIsloading(false);
            setEditing(false);
        }
    };

    useEffect(() => {
        if (!user) {
            toast.warning(t("response_handler.refresh"), {
                position: "bottom-center",
            });
        }
        const profile = user?.user?.profile;
        setCountry(profile?.country || "");
        setCity(profile?.city || "");
        setAddress_one(profile?.address_line_one || "");
        setAddress_two(profile?.address_line_two || "");
        setPhone_one(profile?.main_phone_number || "");
        setPhone_two(profile?.alt_phone_number || "");
        setBio(profile?.bio || "");
        fetchCountry();
    }, []);

    useEffect(() => {
        setValidatedPhone_one(PhoneValidation(phone_one));
        setValidatedPhone_two(PhoneValidation(phone_two));
    }, [phone_one, phone_two]);

    const fetchCountry = async () => {
        const response = await getCountry();
        const res = response.data?.results;
        setCountryList(res);
        setCountryListCopy(res);
    };

    const getBanks = async () => {
        const response = await getAllBanks();
        if (response.status === 200) {
            setBankList(response.data.results);
        }
    };

    const searchCountryList = (e) => {
        setCountry(e.target.value);
        const remainder = countryListCopy.filter((country) =>
            country?.name.toLowerCase().startsWith(e.target.value.toLowerCase())
        );
        setCountryList(remainder);
    };

    const selectCountry = (country) => {
        setCountry(country?.name);
        setSelectedCountry(country);
        setCountryDropdownState(false);
    };

    const {t} = useTranslation();
    return (
        <div className="seller_profile_dashboard_personal_info">
            {!editing ? (
                <>
                    <div className="seller_profile_wrapper">
                        <div className="profile">
                            <h4 className="seller_title">
                                {t("dashboard_edit_form.country")}
                            </h4>
                            <p className="input_seller_text">{country}</p>
                        </div>
                        <div className="profile">
                            <h4 className="seller_title">{t("dashboard_edit_form.city")}</h4>
                            <p className="input_seller_text">{city}</p>
                        </div>
                    </div>
                    <div className="seller_profile_wrapper">
                        <div className="profile">
                            <h4 className="seller_title">
                                {t("dashboard_edit_form.address_one")}
                            </h4>
                            <p className="input_seller_text">{address_one}</p>
                        </div>
                        <div className="profile">
                            <h4 className="seller_title">
                                {t("dashboard_edit_form.address_two")}
                            </h4>
                            <p className="input_seller_text">{address_two}</p>
                        </div>
                    </div>
                    <div className="seller_profile_wrapper">
                        <div className="profile">
                            <h4 className="seller_title">
                                {t("dashboard_edit_form.phone_one")}
                            </h4>
                            <p className="input_seller_text">{phone_one}</p>
                        </div>
                        <div className="profile">
                            <h4 className="seller_title">
                                {t("dashboard_edit_form.phone_two")}
                            </h4>
                            <p className="input_seller_text">{phone_two}</p>
                        </div>
                    </div>
                    <div className="profile profile_about">
                        <h4 className="seller_title">
                            {t("dashboard_edit_form.about_me")}
                        </h4>
                        <p>{bio}</p>
                    </div>

                    <div className="button_payment_request">
                        <button
                            onClick={() => setEditing(true)}
                            className="profile_control_btn"
                        >
                            <span>{t("dashboard_bookcreate.state_edit")}</span>
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className="seller_profile_wrapper">
                        <div className="profile">
                            <h4 className="seller_title">
                                {t("dashboard_edit_form.country")}
                            </h4>
                            <input
                                className="input_seller_text"
                                value={country}
                                onChange={(e) => searchCountryList(e)}
                                onBlur={() =>
                                    setTimeout(() => {
                                        setCountryDropdownState(false);
                                    }, 500)
                                }
                                onFocus={() => setCountryDropdownState(true)}
                                placeholder="Select any country"
                                autoComplete="off"
                            />
                            <div
                                className={`countrySelectDropDown ${
                                    countryDropdownState ? "showCountryDropDown" : ""
                                }`}
                            >
                                {countryList &&
                                    countryList.map((element) => (
                                        <div
                                            key={element?.pk}
                                            onClick={() => selectCountry(element)}
                                            className="nationality_dropdownlist"
                                        >
                                            <span className={`flag flag-icon flag-icon-cm`}></span>
                                            <span className="countryName">{element?.name}</span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="profile">
                            <h4 className="seller_title">{t("dashboard_edit_form.city")}</h4>
                            <input
                                className="input_seller_text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                placeholder="State or town"
                            />
                        </div>
                    </div>
                    <div className="seller_profile_wrapper">
                        <div className="profile">
                            <h4 className="seller_title">
                                {t("dashboard_edit_form.address_one")}
                            </h4>
                            <input
                                className="input_seller_text"
                                value={address_one}
                                onChange={(e) => setAddress_one(e.target.value)}
                                placeholder="House address 1"
                            />
                        </div>
                        <div className="profile">
                            <h4 className="seller_title">
                                {t("dashboard_edit_form.address_two")}
                            </h4>
                            <input
                                className="input_seller_text"
                                value={address_two}
                                onChange={(e) => setAddress_two(e.target.value)}
                                placeholder="House address 2"
                            />
                        </div>
                    </div>
                    <div className="seller_profile_wrapper">
                        <div className="profile">
                            <h4 className="seller_title">
                                {t("dashboard_edit_form.phone_one")}
                            </h4>
                            <input
                                className="input_seller_text"
                                value={phone_one}
                                onChange={(e) => setPhone_one(e.target.value)}
                                placeholder="+237 671 234 567"
                            />
                            {!validatedPhone_one && (
                                <p style={{color: "red"}} className="error_input_msg">
                                    {"invalid phone number"}
                                </p>
                            )}
                        </div>
                        <div className="profile">
                            <h4 className="seller_title">
                                {t("dashboard_edit_form.phone_two")}
                            </h4>
                            <input
                                className="input_seller_text"
                                value={phone_two}
                                onChange={(e) => setPhone_two(e.target.value)}
                                placeholder="+237 671 234 567"
                            />
                            {!validatedPhone_two && (
                                <p style={{color: "red"}} className="error_input_msg">
                                    {"invalid phone number"}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="profile profile_about">
                        <h4 className="seller_title">
                            {t("dashboard_edit_form.about_me")}
                        </h4>
                        <textarea
                            onChange={(e) => setBio(e.target.value)}
                            className="profile_about_text_input"
                        >
              {bio}
            </textarea>
                    </div>
                    <div className="button_payment_request">
                        <button onClick={() => saveEdit()} className="profile_control_btn">
                            <span className="text">{t("dashboard_edit_form.save")}</span>
                            {isloading && (
                                <span className="loading">
                  <ThreeDots/>
                </span>
                            )}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

const PaymentDetails = ({handleTabChange}) => {

	const navigate = useNavigate();

    const [paymentMethod, setPaymentMethod] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountName, setAccountName] = useState("");
    const [bankList, setBankList] = useState([]);
    const [swift, setSwift] = useState("");

    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [isPrimary, setIsPrimary] = useState(false);
    const [validatedAccountNumber, setValidatedAccountNumber] = useState(false);
    const [settlementType, setSettlementType] = useState("");
    const [iban, setIban] = useState("");
    const [isVisible, setVisible] = useState(false);
    const [paymentData, setPaymentData] = useState([]);
    const [ isFormValid, setIsFormValid] = useState(false);
    const [settelementType, setSettelementType] = useState("");
    const [ showEditAndDeleteBtn, setShowEditAndDeleteBtn ] = useState(false);
    const [showCreateBtn, setShowCreateBtn] = useState(true);
    const [forDeleteModal, setForDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [prevSelectedRow, setPrevSelectedRow] = useState(null);
    const [isRowActive, setRowActive] = useState(false);

    const [formData, setFormData] = useState({
        accountName: "",
        accountNumber: "",
        iban: "",
        swiftCode: "",
        phoneNumber: "",
        settlementType: "",
        isPrimary: false,
        bank: "", // Add bank field here
    });

    // Récupération des banques via l'API
    const fetchBanks = async () => {
        const response = await getAllBanks();
        const res = response?.data?.results;
        setBankList(res);
    };

    useEffect(() => {
        fetchBanks();
    }, []);

    const handleRowClick = async (row) => {
        // prevSelectedRow === selectedRow && setRowActive(!isRowActive);
        setPrevSelectedRow(selectedRow);
        setSelectedRow(row);
        setShowEditAndDeleteBtn(showEditAndDeleteBtn => !showEditAndDeleteBtn);  
        setRowActive(!isRowActive);

    };
    const handleEditClick = async () => {
        try {
            // Fetch the specific bank settlement details by row ID
            const response = await getRequest(`/bank-settlements/${selectedRow?.id}/`);
            const data = response?.data;
            console.log('banklist datas', data);
            // Find the corresponding bank in the bankList by comparing data.bank with bankList.pk
            const selectedBank = bankList?.find((bank) => bank.pk === data.bank);

            console.log("Selected Bank:", selectedBank);

            if (data.settlement_type === "mobile" || data.settlement_type === "bank") {
                // Update the form data
                setFormData({
                    accountName: data.account_name || "",
                    accountNumber: data.account_number || "",
                    iban: data.iban || "",
                    swiftCode: data.swift_code || "",
                    phoneNumber: data.phone_number || "",
                    settlementType: data.settlement_type || "", // Only "mobile" or "bank" are valid
                    isPrimary: data.is_primary || false,
                    bank: selectedBank?.name || "", // Set bank name if found
                    validatedAccountNumber: data.settlement_type === "mobile"
                        ? /^\d{8,15}$/.test(data.phone_number)
                        : /^\d{8,20}$/.test(data.account_number),
                });

                // Set payment method to the selected bank's name
                setPaymentMethod(selectedBank?.name || "Unknown Bank");

                // Log after the async state update using a timeout (to demonstrate timing)
                setTimeout(() => {
                    console.log("Payment Method:", selectedBank?.name || t("dashboard_edit.unknown_bank"));
                }, 0);

                setVisible(true);

            }
        } catch (err) {
            console.error("Error fetching bank settlement details:", err);
        }
        
    }

useEffect(() =>{
    console.log('Bank list : ', bankList);
}, [bankList]);

    useEffect(() => {
        const selectedBank = bankList.find((bank) => bank.name === formData.paymentMethod);

        let isValid = true;

        if (!formData.accountName || !formData.paymentMethod) {
            isValid = false; // Ensure account name and payment method are not empty
        }

        if (selectedBank) {
            if (selectedBank.name === "Orange Money" || selectedBank.name === "MTN mobile") {
                const numericPhone = formData.phoneNumber.replace(/[^0-9]/g, "");
                isValid = isValid && /^\d{8,15}$/.test(numericPhone); // Validate phone number as account number
            } else if (selectedBank.name === "CCA" || selectedBank.name === "UBA") {
                isValid =
                    isValid &&
                    /^\d{8,20}$/.test(formData.accountNumber.trim()) && // Validate account number
                    formData.rib && formData.swiftCode && formData.iban; // Ensure other fields are filled
                
                    console.log("account number testing", accountNumber)
            }
        } else {
            isValid = false; // No valid payment method selected
        }

        setIsFormValid(isValid); // Update the state controlling the button
    }, [formData, bankList]);




    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            paymentMethod,
        }));
    }, [paymentMethod]);

    const fetchpaymentData = async () => {
        const response = await getRequest("/bank-settlements/");
        if (response.status === 200) {
            const fetchedData = response?.data?.results;
            const updatedData = fetchedData.map(paymentItem => ({
                ...paymentItem,
                bank_name: bankList.find( bank  => bank.pk === paymentItem.bank)?.name || "unknown"
            }));
            console.log(updatedData);
            setPaymentData(response.data.results);
            return
        }
    };
    useEffect(() => {
        fetchpaymentData()
    }, []);

    useEffect(() => {
        fetchBanks();
    }, []);

    // Handle payment method change (mobile vs bank)


    const handlePaymentMethodChange = (method) => {
        const selectedBank = bankList.find((bank) => bank.name === method); // Find the selected bank
        const isMobilePayment = method === 'MTN mobile' || method === 'Orange Money'; // Check for mobile payment methods

        setFormData((prevFormData) => ({
            ...prevFormData,
            paymentMethod: method, // Update the payment method
            settlementType: isMobilePayment
                ? "mobile" // Set to "mobile" for MTN mobile or Orange Money
                : "bank", // Default to bank for other banks
            bank: selectedBank || method === 'CCA' || method === 'UBA' ? method : null, // Set bank to the method name for CCA/UBA
            accountName: prevFormData.accountName || "", // Retain accountName
            accountNumber: isMobilePayment === true
                ? prevFormData.phoneNumber || "" // Use phoneNumber for mobile payments
                : prevFormData.accountNumber || "", // Use accountNumber for banks
            phoneNumber: isMobilePayment
                ? prevFormData.phoneNumber || "" // Retain phoneNumber for mobile payments
                : "", // Clear phoneNumber for banks
            rib: selectedBank || method === 'CCA' || method === 'UBA' ? prevFormData.rib || "" : "", // Retain RIB for banks, clear for others
            swiftCode: selectedBank || method === 'CCA' || method === 'UBA' ? prevFormData.swiftCode || "" : "", // Retain SWIFT for banks, clear for others
            iban: selectedBank || method === 'CCA' || method === 'UBA' ? prevFormData.iban || "" : "", // Retain IBAN for banks, clear for others
            isPrimary: prevFormData.isPrimary || false, // Retain isPrimary

        }));
        console.log("Selected Method:", method);
        console.log("Settlement Type:",
            method === 'MTN mobile' ? "mobile" :
                method === 'Orange Money' ? "mobile" :
                    method === 'CCA' || method === 'UBA' ? "bank" :
                        selectedBank ? "bank" : "other"
        );

    };


const deletePaymentMethod = async () => {

    const idMethod = selectedRow?.id;
    setIsLoading(true);
    try{
        const response = await deleteRequest(`/bank-settlements/${idMethod}/`);
        if(response.status === 204){
            toast.success( t("dashboard_edit.payment_details.successfully_deleted"), {
                position: "bottom-right",
            });

        }else{
            toast.error( t("dashboard_edit.payment_details.deletion_failed"), {
                position: "bottom-right",
            });
        }

    }catch(error){
        console.error( t("dashboard_edit.payment_details.error_deletion"), error);
        toast.error(  t("dashboard_edit.payment_details.error_deletion"), {
            position: "bottom-right",
        });
    }
    setIsLoading(false);
}

const preventDeletion = () => {
    setForDeleteModal(true);
    setVisible(true);
}
const proceedDeletion = () => {
    setIsLoading(true)
    deletePaymentMethod();
    setVisible(false);
    fetchpaymentData();
    setIsLoading(false);
    setShowEditAndDeleteBtn(false);
    setForDeleteModal(false);
    setIsLoading(false);

}


    useEffect(() => {
        console.log('Updated formData:', formData);
    }, [formData]);


    // Validation logic for the form
    const validateForm = () => {
        const phoneRegex = /^\+\d{1,4}\s?\d{4,14}$/; // Regex for phone number validation
        const isPhoneValid = formData.settlementType === "mobile"
            ? phoneRegex.test(formData.phoneNumber)
            : true;

        const isAccountNumberValid = formData.settlementType === "bank"
            ? formData.accountNumber.length >= 10 // Example validation for bank
            : true;

        return (
            formData.accountName.trim() !== "" && // Ensure Account Name is filled
            isPhoneValid && // Validate phone if mobile
            isAccountNumberValid // Validate account number if bank
        );
    };



    useEffect(() => {
        setIsFormValid(validateForm());
    }, [formData]);

    // Submit logic (create or update payment)
    const handleSubmit = async () => {
        // Validate mobile payment (MTN mobile or Orange Money)
        if (
            (paymentMethod === "MTN mobile" || paymentMethod === "Orange Money") &&
            !formData.phoneNumber
        ) {
            toast.error(t("dashboard_edit.payment_details.warning_phone"), {
                position: "bottom-right",
            });
            return;
        }

        // Validate account number for bank payments (UBA or CCA)
        if (
            (paymentMethod === "UBA" || paymentMethod === "CCA") &&
            !formData.accountNumber
        ) {
            toast.error(t("dashboard_edit.payment_details.warning_account"), {
                position: "bottom-right",
            });
            return;
        }

        // Validate account name
        if (!formData.accountName.trim()) {
            toast.error(t("dashboard_edit.payment_details.warning_name"), {
                position: "bottom-right",
            });
            return;
        }

        // Resolve settlement type
        const settlementType =
            (paymentMethod === "MTN mobile" || paymentMethod === "Orange Money")
                ? "mobile"
                : "bank";

        // Resolve account number
        const account_number_value =
            formData.settlementType === "mobile" ? formData.phoneNumber : formData.accountNumber;

        // Resolve selected bank
        const selectedBank = bankList.find((bank) => bank.name === formData.bank);

        // Construct payload
        const payload = {
            bank: selectedBank?.pk || null,
            settlement_type: formData.settlementType,
            account_number: account_number_value, // Use resolved account number
            account_name: formData.accountName.trim(),
            rib: formData.settlementType === "bank" ? formData.accountNumber : null,
            swift_code: formData.settlementType === "bank" ? formData.swiftCode || null : null,
            iban: formData.settlementType === "bank" ? formData.iban || null : null,
            phone_number: formData.settlementType === "mobile" ? formData.phoneNumber : null,
            is_primary: formData.isPrimary,
        };


		

        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const token = user?.access;

            const headers = {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            };

            let response;
            const isEditMode = !!selectedRow?.id;
            if (isEditMode) {
                // PUT request for editing payment details
                response = await axios.put(
                    `https://hooyia.herokuapp.com/api/v1/bank-settlements/${selectedRow.id}/`,
                    payload,
                    { headers }
                );

                if (response.status === 200) {
                    toast.success(t("dashboard_edit.payment_details.update_success"), {
                        position: "bottom-right",
                    });
					// handleTabChange('payment_details');
					// navigate(`/${user?.user?.first_name}/profile?edit=true`);

                } else {
                    toast.error(t("dashboard_edit.payment_details.update_failed"), {
                        position: "bottom-right",
                    });
                }
            } else {
                // POST request for creating payment details
                response = await postRequest("/bank-settlements/", payload);

                if (response.status === 201) {
                    toast.success(t("dashboard_edit.payment_details.create_success"), {
                        position: "bottom-right",
                    });
					navigate(`/${user?.user?.first_name}/profile?edit=true`);
                } else {
                    toast.error(t("dashboard_edit.payment_details.create_failed"), {
                        position: "bottom-right",
                    });
                }
            }
        } catch (error) {
            console.error("Error in handleSubmit:", error);
            toast.error(t("dashboard_edit.payment_details.create_error"), {
                position: "bottom-right",
            });
            if (error.response) {
              console.error("Server response data:", error.response.data);
              toast.error(t("dashboard_edit.payment_details.create_error"));
            } else {
              toast.error(t("dashboard_edit.payment_details.create_network_error"));
              console.error("Server response data:", error.response.data);

            }


                console.error("Full error response:", {
                  status: error.response?.status,
                  data: error.response?.data,
                  headers: error.response?.headers
                });
                // ... reste du code ...
        }
        finally{
            handleModalVisible();
            fetchpaymentData();
            setSelectedRow(null);
            setShowEditAndDeleteBtn(false);


        }
    };




    const handleModalVisible = () => {
        setVisible((visible) => !visible);
        resetFormData(); // Reset the formData
        setForDeleteModal(false);
        setSelectedRow(null);
        setShowCreateBtn(!showEditAndDeleteBtn);
        setShowEditAndDeleteBtn(false);
    };

// Function to reset formData to initial values
    const resetFormData = () => {
        setFormData({
            accountName: "",
            accountNumber: "",
            iban: "",
            swiftCode: "",
            phoneNumber: "",
            settlementType: "",
            isPrimary: false,
            bank: "",
            paymentMethod: "",
        });
    };


    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate a loading state (e.g., fetching data)
        const timer = setTimeout(() => setIsLoading(false), 300);
        return () => clearTimeout(timer);
    }, []);


    const [shakeClass, setShakeClass] = useState("");

    useEffect(() => {
      // Ajoute la classe d'animation au montage
      setShakeClass("shake");
      const timer = setTimeout(() => {
        setShakeClass(""); // Supprime la classe après l'animation
      }, 500); // La durée doit correspondre à celle de l'animation
  
      return () => clearTimeout(timer);
    }, [showEditAndDeleteBtn]);

    const { t } = useTranslation();

    return (
        <div className="seller-container">
            <div className="content-wrapper">
                <div className="button-wrapper">
                   { showEditAndDeleteBtn ?
                   (
                    <>
                        <button className={`edit-btn ${shakeClass}`} onClick={handleEditClick}>
                            <FaPenSquare className="icon"/>
                            <span>{t('dashboard_edit.payment_details.edit')} </span>
                        </button>
                        <button className={`delete-btn ${shakeClass}`} onClick={preventDeletion}>
                            <FaTrash className="icon"></FaTrash>
                            <span>{t('dashboard_edit.payment_details.delete')}</span>
                        </button>
                    </>
                    )
                 :
                    (
                        <button className={`create-btn ${shakeClass}`} onClick={handleModalVisible}>
                        {t('dashboard_edit.payment_details.create_payment')}
                        </button>
                    )
                    }
                </div>
                {
                    isVisible ?
                        <AnimatePresence mode={"wait"} onExitComplete={() => null}>
                        {isLoading ? (
                                <motion.div
                                    key="loader"
                                    initial={{opacity: 0, scale: 0.8}}
                                    animate={{opacity: 1, scale: 1}}
                                    exit={{opacity: 0, scale: 0.8}}
                                    transition={{duration: 0.1}}
                                    className="loader-wrapper"
                                >
                                    <div className="spinner">{t('dashboard_edit.payment_details.loading')}</div>
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="content"
                                    className="panel-seller-profile"
                                    initial={{opacity: 0, y: 50}}
                                    animate={{opacity: 1, y: 0}}
                                    exit={{opacity: 0, y: 50}}
                                    transition={{duration: 0.5}}
                                >
                                    <motion.div
                                        className="instructions-seller-profile"
                                        initial={{scale: 0.9, opacity: 0}}
                                        animate={{scale: 1, opacity: 1}}
                                        exit={{scale: 0.9, opacity: 0}}
                                        transition={{duration: 0.5}}
                                    >
                              <span className="close" onClick={handleModalVisible}>
                                &times;
                              </span>
                                        { !forDeleteModal ? <div className="payment_details_wrapper">
                                            <h2 className="payment-info">
                                                <HiInformationCircle/> {t("dashboard_edit.payment_details.instruction")}
                                            </h2>
                                            <div className="payment_method_account_name">
                                                <div className="payment_method_selector">
                                                    <label>{t("dashboard_edit.payment_details.select_method")}</label>
                                                    <select
                                                        value={formData.paymentMethod}
                                                        onChange={(e) => handlePaymentMethodChange(e.target.value)} // Pass methodName
                                                        className="select-dropdown"
                                                    >
                                                        <option value="">{t("dashboard_edit.payment_details.select_option")}</option>
                                                        {bankList && bankList.length > 0 ? (
                                                            bankList.map((bank) => (
                                                                <option key={bank.pk}
                                                                        value={bank.name}> {/* Use name instead of id */}
                                                                    {bank.name}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <option disabled>{t("dashboard_edit.payment_details.no_bank")}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="field account_name_field">
                                                    <label>{t("dashboard_edit.payment_details.account_name")}</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter account name (must be valid for this account)"
                                                        value={formData.accountName} // Bind to formData.accountName
                                                        onChange={(e) => setFormData({
                                                            ...formData,
                                                            accountName: e.target.value
                                                        })}
                                                    />
                                                </div>
                                            </div>

                                            {(formData.paymentMethod === "Orange Money" || formData.paymentMethod === "MTN mobile") && (
                                                <div className="mobile_money_fields">
                                                    <div className="field">
                                                        <label>{t("dashboard_edit.payment_details.account_number")} ({t("dashboard_edit.payment_details.phone_number")})</label>
                                                        <CountryPhoneInput
                                                            setCountry={setCountry}
                                                            setPhone={(value) => {
                                                                setPhone(value); // Only update phone
                                                                setFormData((prevFormData) => ({
                                                                    ...prevFormData,
                                                                    phoneNumber: value, // Update phoneNumber in form data
                                                                }));
                                                            }}
                                                            phone={formData.phoneNumber} // Bind to formData.phoneNumber
                                                        />


                                                        {!formData.validatedAccountNumber && (
                                                            <p className="error">{t("dashboard_edit.payment_details.invalid_phone")}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            )}


                                            {(formData.paymentMethod === "CCA" || formData.paymentMethod === "UBA") && (
                                                <div className="bank_fields">
                                                    <div className="field">
                                                        <label>{t("dashboard_edit.payment_details.account_number")}r</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter account number"
                                                            value={formData.accountNumber} // Bind to formData.accountNumber
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    accountNumber: e.target.value,
                                                                })
                                                            }
                                                            maxLength={20}
                                                            pattern="\d*"
                                                        />
                                                        {!formData.validatedAccountNumber && (
                                                            <p className="error">{t("dashboard_edit.payment_details.invalid_account")}</p>
                                                        )}
                                                    </div>
                                                    <div className="field">
                                                        <label>RIB</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter RIB"
                                                            value={formData.rib} // Bind to formData.rib
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    rib: e.target.value,
                                                                })
                                                            }
                                                            maxLength={23} // RIB limit
                                                            pattern="\d*"
                                                        />
                                                        {!formData.validatedAccountNumber && (
                                                            <p className="error">{t("dashboard_edit.payment_details.invalid_rib")}</p>
                                                        )}
                                                    </div>
                                                    <div className="field">
                                                        <label>SWIFT</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter SWIFT code"
                                                            value={formData.swiftCode} // Bind to formData.swiftCode
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    swiftCode: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="field">
                                                        <label>IBAN</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter IBAN"
                                                            value={formData.iban} // Bind to formData.iban
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    iban: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}


                                            <div className="primary_checkbox">
                                                <input
                                                    type="checkbox"
                                                    checked={formData.isPrimary} // Bind to formData.isPrimary
                                                    onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        setFormData((prevFormData) => ({
                                                            ...prevFormData,
                                                            isPrimary: isChecked, // Update isPrimary based on the checkbox value
                                                        }));
                                                    }}
                                                />
                                                <label>{t("dashboard_edit.payment_details.set_primary")}y</label>
                                            </div>


                                            <button
                                                className="profile_control_btn"
                                                disabled={!isFormValid}
                                                onClick={handleSubmit}
                                            >
                                                {selectedRow ? t("dashboard_edit.payment_details.update") : t("dashboard_edit.payment_details.submit")}
                                            </button>
                                        </div>
                                        :
                                        (
                                            <>
                                                <p className="deletion-modal-text">{t("dashboard_edit.payment_details.delete_warning")}<span className="to-be-deleted-number"> &nbsp;&nbsp;&nbsp;&nbsp; {selectedRow?.account_number}</span>. </p>
                                                <br />
                                                <p className="deletion-modal-text"> &nbsp;&nbsp;&nbsp;&nbsp; {t("dashboard_edit.payment_details.continue")}</p>
                                                <button className="proceed" onClick={proceedDeletion} >{isLoading ? <span className="loader"></span> : t('dashboard_edit.payment_details.proceed')}</button>
                                            </>
                                        )
                                        }
                                    </motion.div>
                                </motion.div>
                        )}
                        </AnimatePresence>
                        : (
                            <div className="datatable-wrapper">
                                <DataTable
                                    columns={[
                                        {
                                            name: (
                                                <div className="row_item_stat row_item_header_stat">
                                                    {t("dashboard_edit.payment_details.acc_name")}
                                                </div>
                                            ),
                                            selector: (row) => <div className={`row_item_stat ${selectedRow === row  && isRowActive ? 'active' : ''}`}>{row.account_name}</div>,
                                            sortable: true,
                                            center: true
                                        },
                                        {
                                            name: (
                                                <div className="row_item_stat row_item_header_stat">
                                                    {t("dashboard_edit.payment_details.primary")}
                                                </div>
                                            ),
                                            selector: (row) => (
                                                <div className={`row_item_stat ${selectedRow === row  && isRowActive ? 'active' : ''}`}> {row.is_primary === false ? (
                                                    <FaTimes color="red" width={20} height={20}/>
                                                ) : (
                                                    <FaCheck color="green" width={20} height={20}/>
                                                )}</div>
                                            ),
                                            sortable: true,
                                            center: true
                                        },
                                        {
                                            name: (
                                                <div className="row_item_stat row_item_header_stat">
                                                    {t("dashboard_edit.payment_details.account_no")}
                                                </div>
                                            ),
                                            selector: (row) => (
                                                <div className={`row_item_stat ${selectedRow === row  && isRowActive ? 'active' : ''}`}>{row.account_number}</div>
                                            ),
                                            sortable: true,
                                            center: true
                                        },
                                        {
                                            name: (
                                                <div className="row_item_stat row_item_header_stat">
                                                    {t("dashboard_edit.payment_details.bank")}
                                                </div>
                                            ),
                                            selector: (row) => (
                                                <div className={`row_item_stat ${selectedRow === row  && isRowActive ? 'active' : ''}`}>
                                                    {row.bank_name ? row.bank_name : "N/A"}
                                                </div>
                                            ),
                                            sortable: true,
                                            center: true
                                        },
                                        {
                                            name: (
                                                <div className="row_item_stat row_item_header_stat">
                                                    IBAN
                                                </div>
                                            ),
                                            selector: (row) => (
                                                <div className={`row_item_stat ${selectedRow === row  && isRowActive ? 'active' : ''}`}>
                                                    {row.iban ? row.iban : "N/A"}
                                                </div>
                                            ),
                                            sortable: true,
                                            center: true
                                        },
                                        {
                                            name: (
                                                <div className="row_item_stat row_item_header_stat">
                                                    {t("dashboard_edit.payment_details.phone")}
                                                </div>
                                            ),
                                            selector: (row) => (
                                                <div className={`row_item_stat ${selectedRow === row  && isRowActive ? 'active' : ''}`}>
                                                    {row.phone_number ? row.phone_number : "N/A"}
                                                </div>
                                            ),
                                            sortable: true,
                                            center: true
                                        },
                                        {
                                            name: (
                                                <div className="row_item_stat row_item_header_stat">
                                                RIB
                                                </div>
                                            ),
                                            selector: (row) => (
                                                <div className={`row_item_stat ${selectedRow === row  && isRowActive ? 'active' : ''}`}>
                                                    {row.rib ? row.rib : "N/A"}
                                                </div>
                                            ),
                                            sortable: true,
                                            center: true
                                        },
                                        {
                                            name: (
                                                <div className="row_item_stat row_item_header_stat">
                                                    {t("dashboard_edit.payment_details.settlement")}
                                                </div>
                                            ),
                                            selector: (row) => (
                                                <div className={`row_item_stat ${selectedRow === row  && isRowActive ? 'active' : ''}`}>
                                                    {row.settlement_type ? row.settlement_type : "N/A"}
                                                </div>
                                            ),
                                            sortable: true,
                                            center: true
                                        },
                                        {
                                            name: (
                                                <div className="row_item_stat row_item_header_stat">
                                                    Swift
                                                </div>
                                            ),
                                            selector: (row) => (
                                                <div className={`row_item_stat ${selectedRow === row  && isRowActive ? 'active' : ''}`}>
                                                    {row.swift_code ? row.swift_code : "N/A"}
                                                </div>
                                            ),
                                            sortable: true,
                                            center: true
                                        },
                                    ]}
                                    data={paymentData}
                                    pagination
                                    striped
                                    selectableRowsHighlight
                                    onRowClicked={handleRowClick}
                                    paginationPerPage={5}
                                    paginationRowsPerPageOptions={[5, 10, 15]}
                                    highlightOnHover
                                    fixedHeader
                                    fixedHeaderScrollHeight="400px"
                                    responsive
                                />
                            </div>
                        )
                }
            </div>
        </div>
    );
};

const Achievements = () => {
    return (
        <>
            <p></p>
        </>
    );
};

const MySellingBooks = () => {
    const {limit, offset} = usePagination();
    const [filterBy, setFilterBy] = useState(""); // Filtre actif
    const [activeFilter, setActiveFilter] = useState("all"); // Bouton actif
    const [searchString, setSearchString] = useState(""); // Requête de recherche
    const [isLoading, setIsLoading] = useState(false); // Loader
    const [books, setBooks] = useState([]); // Tous les livres
    const [filteredBooks, setFilteredBooks] = useState([]); // Livres filtrés

    const navigate = useNavigate();

    // Charger les livres au montage et à chaque changement de page
    useEffect(() => {
        setIsLoading(true);
        fetchMyBooks();
    }, [offset]);

    // Charger les livres depuis l'API
    const fetchMyBooks = async () => {
        try {
            const res = await getRequest(
                `/seller/ebook/owner-ebook/?limit=${limit}&offset=${offset}`
            );
            const results = res.data?.results || []; // Assurez-vous que c'est un tableau
            setBooks(Array.isArray(results) ? results : []); // Initialisez `books` comme tableau
            setFilteredBooks(Array.isArray(results) ? results : []); // Initialisez `filteredBooks`
        } catch (error) {
            console.error("Erreur lors de la récupération des livres :", error);
            setBooks([]); // Valeur par défaut si l'API échoue
            setFilteredBooks([]);
        } finally {
            setIsLoading(false);
        }
    };

    // Appliquer les filtres
    const filterBooks = () => {
        if (!Array.isArray(books)) return; // Assurez-vous que `books` est un tableau

        let filtered = [...books];

        // Filtrer par état (in_review_mode)
        if (activeFilter === "active") {
            filtered = filtered.filter((book) => book.in_review_mode === false);
        } else if (activeFilter === "pending") {
            filtered = filtered.filter((book) => book.in_review_mode === true);
        }

        // Filtrer par texte
        if (searchString) {
            filtered = filtered.filter((book) =>
                book.name.toLowerCase().includes(searchString.toLowerCase())
            );
        }

        setFilteredBooks(filtered);
    };

    const filterButton = (state) => {
        setActiveFilter(state);
        setFilterBy(state);
    };

    const filterInput = (state) => {
        setSearchString(state);
    };

    useEffect(() => {
        filterBooks();
    }, [activeFilter, searchString, books]);

    return (
        <div className="seller_mybooks_page">
            <div className="mybooks_page">
                <main className="main_user_section">
                    {/* Barre de filtres */}
                    <div className="my_seller_book_filterWrapper">
                        <div>
                            <button
                                onClick={() => filterButton("all")}
                                className={`filter_by_btn ${
                                    activeFilter === "all" ? "filteringByActive" : ""
                                }`}
                            >
                                All
                            </button>
                            <button
                                onClick={() => filterButton("active")}
                                className={`filter_by_btn ${
                                    activeFilter === "active" ? "filteringByActive" : ""
                                }`}
                            >
                                Active
                            </button>
                            <button
                                onClick={() => filterButton("pending")}
                                className={`filter_by_btn ${
                                    activeFilter === "pending" ? "filteringByActive" : ""
                                }`}
                            >
                                Pending
                            </button>
                        </div>
                        {/* Champ de recherche */}
                        <div className="search_sellerbook_filter">
                            <input
                                value={searchString}
                                onChange={(e) => filterInput(e.target.value)}
                                type="text"
                                placeholder="Search..."
                            />
                        </div>
                    </div>

                    {/* Liste des livres */}
                    <div className="user_main_grid">
                        {isLoading ? (
                            <LoadingPage/>
                        ) : filteredBooks.length < 1 ? (
                            <div className="seller_with_no_book">
                                <div className="title">Nothing found !!!</div>
                            </div>
                        ) : (
                            filteredBooks.map((book) => (
                                <SellerBook key={book?.slug} book={book}/>
                            ))
                        )}
                    </div>

                    {/* Pagination */}
                    <Pagination/>
                </main>
            </div>
        </div>
    );
};
