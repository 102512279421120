import React, { useEffect, useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

import i18next, { changeLanguage } from 'i18next';
import Home from './Home/Home'
import Footer from './Footer/Footer'
import Nav from './Nav/Nav'
import { Route, Routes, useLocation } from 'react-router-dom'
import Signup from './Auth/Signup'
import Login from './Auth/Login'

import NotFound from './Home/NotFound'
import ForgotPassword from './Auth/ForgotPassword'
import ChangePassword from './Auth/ChangePassword'
import Ebooks from './Ebook/Ebooks'
import NavExplore from './Nav/NavExplore'
import ProductDetails from '../Products/ProductDetails'

import Faq from './FAQ/Faq'
import Cart from '../Cart&WishList/Cart';
import Contact from './Contact/Contact'
import EmailVerify from './Auth/EmailVerify'
import EmailVerified from './Auth/EmailVerified'
import PasswordResetLinkSent from './Auth/PasswordResetLinkSent'
import PasswordResetSuccess from './Auth/PasswordResetSuccess'
import PasswordResetLinkExpired from './Auth/PasswordResetLinkExpired'
import Guide from './Marketing/Guide/Guide';
import { getRequest } from '../../Resquest';
import Explore from './Marketing/Explore/Explore';
import UserProfile from '../Profile/UserProfile';
import StoreProfile from '../Profile/StoreProfile';
import SellerPrivacy from '../SellerAccount/SellerPrivacy';
import RequestSubmitted from './Contact/RequestSubmitted';
import PhoneVerify from './Auth/PhoneVerify';
import AboutPage from './About/AboutPage';
import RefundPolicy from '../SellerAccount/RefundPolicy';
import PrivacyPolicy from '../SellerAccount/PrivacyPolicy';
import { routeSeoConfig, withSEO } from '../../lib/withSEO';

const AppN = () => {
  const [category_list, setCategory_list] = useState([])
  const [fetching_cat, setFetching_cat] = useState(false)
  const route = useLocation()

  useEffect(() => {
		fetchCategories()
	}, [])

	const fetchCategories = async() => {
    setFetching_cat(true)
		const response = await getRequest('/public/list-category/')
		response?.data
			&& setCategory_list(response?.data?.results)
    setFetching_cat(false)
	}

  const toggleLang = () => {
    const lang = i18next.language
    i18next.changeLanguage(lang ==='en'? 'fr': 'en')
    window.location.reload()
  }
  
  const changeLang = (lang, e) => {
    i18next.changeLanguage(lang)
    window.location.reload()
  }

  const EnhancedRegister = withSEO(Signup, routeSeoConfig.register);

  return (
    <div className='App'>
      {
        route.pathname === '/ebooks'
        ? <NavExplore category_list={ category_list } fetching_cat={fetching_cat} changeLang={changeLang} /> : <Nav category_list={ category_list } fetching_cat={fetching_cat} changeLang={changeLang} />
      }
      <Routes>
        <Route path='/cart' element={ <Cart /> } />
        <Route path='/tour/guide' element={ <Guide /> } />
        <Route path='/ebooks' element={ <Ebooks /> } />
        <Route path='/ebooks/:id' element={ <ProductDetails category_list={ category_list } /> } />
        <Route path='/register' element={ <EnhancedRegister /> } />
        <Route path='/login' element={ <Login /> } />
        <Route path='/me/:id' element={ <StoreProfile /> } />
        <Route path='/explore' element={ <Explore /> } />
        <Route path='/emailverification' element={ <EmailVerify /> } />
        <Route path='/verify-nummber' element={ <PhoneVerify /> } />
        <Route path='/verify-user/?' element={ <EmailVerified /> } />
        <Route path='/help/contact-support' element={ <Contact /> } />
        <Route path='/help/message-submitted' element={ <RequestSubmitted /> } />
        <Route path='/frequently-asked-question' element={ <Faq /> } />
        <Route path='/forgot-password' element={ <ForgotPassword /> } />
        <Route path='/password-reset-link-sent' element={ <PasswordResetLinkSent />} />

        <Route path='/reset-password/?' element={ <ChangePassword /> } />
        <Route path='/password-reset-success' element={ <PasswordResetSuccess />} />
        <Route path='/password-reset-link-expired' element={ <PasswordResetLinkExpired />} />
        <Route path='/about-us' element={ <AboutPage />} />
				<Route path='/terms-and-conditions' element={<SellerPrivacy />} />
				<Route path='/refund-policy' element={<RefundPolicy />} />
				<Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/' element={ <Home /> } />
        <Route path='*' element={ <NotFound /> } />
      </Routes>
      <Footer changeLang={toggleLang} />
    </div>
  )
}

export default AppN
