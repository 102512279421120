import React, { Suspense } from 'react'
import { Link } from 'react-router-dom'
import Ebook from './Ebook'

const ProductGrid = ({ products, isloading }) => {

	let placeholder = new Array(13).fill(0)
	return (
		<div className='home_grid'>
			{
				isloading ? 
				placeholder.map((i, index) => (
				<Link key={index} className='home_grid_item'>
					<div className='imgWrapper loading_skeleton'>
					</div>
					<div className='desc '>
						<p className='price loading_skeleton skeleton_text'></p>
						<p className='sold-rating loading_skeleton skeleton_rating'></p>
						<p className='price loading_skeleton skeleton_text'></p>
					</div>
				</Link>
				))
				:
				products.map(product => (
					<Suspense key={product.slug}>
						<Ebook product={product} />
					</Suspense>
				))

			}
		</div>


	)
}

export default ProductGrid

